export enum PermissionRolesEnum {
   EDIT_CUSTOMER = "edit_customer",
   RESERVE_MSISDN = "reserve_msisdn",
   CREATE_ORDER = "create_order",
   CREATE_ACQUISITION_ORDER = "create_acquisition_order",
   CREATE_RETENTION_ORDER = "create_retention_order",
   CREATE_MNP_ORDER = "create_mnp_order",
   CREATE_FTTB_ORDER = "create_fttb_order",
   CREATE_FMC_ORDER = "create_fmc_order",
   ADD_PACKAGE = "add_package",
   REMOVE_PACKAGE = "remove_package",
   ADD_BARRING = "add_barring",
   REMOVE_BARRING = "remove_barring",
   ADD_SERVICE = "add_service",
   REMOVE_SERVICE = "remove_service",
   CHANGE_MSISDN = "change_msisdn",
   CHANGE_SIM = "change_sim",
   ACTIVATE_SUBSCRIPTION = "activate_subscription",
   DEALER_BALANCE_TRANSFER = "dealer_balance_transfer",
   CHANGE_SUBSCRIPTION_TYPE = "change_subscription_type",
   PERSONALIZE_FOR_EXISTING_CUSTOMER = "personalize_for_existing_customer",
   UPLOAD_DOCUMENT = "upload_document",
   CAMPAIGN_OFFER_ACTION = "campaign_offer_action",
   UPLOAD_ACQUISITION_DOCUMENT = "upload_acquisition_document",
   CUSTOMER_MANAGEMENT_PERMISSION = "customer_management_permission",
   BILLING_ACCOUNT_ID_RESERVATION = "billing_account_id_reservations",
   CAN_SKIP_CUSTOMER_IDENTIFICATION = "can_skip_customer_identification_validation_for_coordinator_msisdn",
}