import ApplicationRoute from "../../../routes/ApplicationRoute";

const routes = {
	FMC_POSTPAID_FLOW: new ApplicationRoute("/fmc-postpaid-flow"),
	FMC_FLOW_POSTPAID_SEARCH: new ApplicationRoute("/fmc-postpaid-flow/search"),
	FMC_FLOW_POSTPAID_ADDRESS: new ApplicationRoute("/fmc-postpaid-flow/address"),
	FMC_FLOW_POSTPAID_PLAN: new ApplicationRoute("/fmc-postpaid-flow/plan"),
	FMC_FLOW_POSTPAID_OPTIONS: new ApplicationRoute("/fmc-postpaid-flow/options"),
	FMC_FLOW_POSTPAID_SUMMARY: new ApplicationRoute("/fmc-postpaid-flow/summary"),
	// FMC B2B flow routes
	FMC_POSTPAID_B2B_FLOW: new ApplicationRoute("/fmc-b2b-flow"),
	FMC_FLOW_B2B_SEARCH_PATH: new ApplicationRoute("/fmc-b2b-flow/search"),
	FMC_FLOW_B2B_POSTPAID_ADDRESS: new ApplicationRoute("/fmc-b2b-flow/address"),
	FMC_FLOW_B2B_POSTPAID_PLAN: new ApplicationRoute("/fmc-b2b-flow/plan"),
	FMC_FLOW_B2B_POSTPAID_OPTIONS: new ApplicationRoute("/fmc-b2b-flow/options"),
	FMC_FLOW_B2B_POSTPAID_SUMMARY: new ApplicationRoute("/fmc-b2b-flow/summary"),

};

const FMC_POSTPAID_FLOW_PATH: string = routes.FMC_POSTPAID_FLOW.createLink();
const FMC_FLOW_POSTPAID_SEARCH_PATH: string = routes.FMC_FLOW_POSTPAID_SEARCH.createLink();
const FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH: string = routes.FMC_FLOW_POSTPAID_ADDRESS.createLink();
const FMC_FLOW_POSTPAID_PLAN_PATH: string = routes.FMC_FLOW_POSTPAID_PLAN.createLink();
const FMC_FLOW_POSTPAID_OPTIONS_PATH: string = routes.FMC_FLOW_POSTPAID_OPTIONS.createLink();
const FMC_FLOW_POSTPAID_SUMMARY_PATH: string = routes.FMC_FLOW_POSTPAID_SUMMARY.createLink();

// FMC postpaid B2B routes
const FMC_POSTPAID_B2B_FLOW: string = routes.FMC_POSTPAID_B2B_FLOW.createLink();
const FMC_FLOW_B2B_SEARCH_PATH: string = routes.FMC_FLOW_B2B_SEARCH_PATH.createLink();
const FMC_FLOW_B2B_POSTPAID_ADDRESS_PATH: string = routes.FMC_FLOW_B2B_POSTPAID_ADDRESS.createLink();
const FMC_FLOW_B2B_POSTPAID_PLAN: string = routes.FMC_FLOW_B2B_POSTPAID_PLAN.createLink();
const FMC_FLOW_B2B_POSTPAID_OPTIONS_PATH: string = routes.FMC_FLOW_B2B_POSTPAID_OPTIONS.createLink();
const FMC_FLOW_B2B_POSTPAID_SUMMARY: string = routes.FMC_FLOW_B2B_POSTPAID_SUMMARY.createLink();

export {
	routes,
	FMC_FLOW_POSTPAID_SEARCH_PATH,
	FMC_POSTPAID_FLOW_PATH,
	FMC_FLOW_POSTPAID_PLAN_PATH,
	FMC_FLOW_POSTPAID_SUMMARY_PATH,
	FMC_FLOW_POSTPAID_FTTB_ADDRESS_PATH,
	FMC_FLOW_POSTPAID_OPTIONS_PATH,
	FMC_POSTPAID_B2B_FLOW,
	FMC_FLOW_B2B_SEARCH_PATH,
	FMC_FLOW_B2B_POSTPAID_ADDRESS_PATH,
	FMC_FLOW_B2B_POSTPAID_PLAN,
	FMC_FLOW_B2B_POSTPAID_OPTIONS_PATH,
	FMC_FLOW_B2B_POSTPAID_SUMMARY,
};

