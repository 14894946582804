import React, { FC, useCallback, useEffect, useState } from "react";
import {
	CircularProgress
} from "../../../../shared/components";
import {
	INDEX_PATH,
} from "../../Routes/FMCPageRoutes";
import { useIntl, FormattedMessage } from "react-intl";
import CommonMessages from "../../../../Common.messages";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import { FMCMessages } from "../../FMC.messages";
import {
	Mutation,
	MutationCreate_Fmc_OrderArgs,
	MutationCreate_Fttb_OrderArgs,
	MutationCreate_Subscription_PreorderArgs,
	Query,
	QueryLimited_SubscriptionArgs,
	QueryAssociated_Subscription_TypeArgs
} from "../../../../graphql/types";
import {
	CREATE_FMC_ORDER,
	CREATE_FTTB_ORDER,
	GET_FTTB_SUBSCRIPTION,
} from "../../../../graphql/mutations/order";
import { PlanCard } from "./../../Pages/Summary/PlanCard";
import { OptionsCard } from "../../../../shared/components/option/OptionsCard";
import { FmcRouteState } from "../../fmc.type";
import { CREATE_SUBSCRIPTION_PREORDER } from "../../../../graphql/queries/preorder";
import { FmcPreOrderState } from "../../../../graphql/localTypes";
import { config } from "../../../../config/constants";
import moment from "moment";
import { FmcService } from "../../service/FmcService";
import { FttbService, FTTB_FAIL } from "../../../fttb/service/FttbService";
import { SuccessMessageList } from "../../../../shared/components/SuccessMessageTemplate";
import { FttbSummaryDetails } from "../../../fttb/FttbSummaryDetails";
import { useFormik } from "formik";
import { useAuthDataContext } from "../../../../shared/hooks/AuthentificationProvider";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { FmcPostpaidRouteState } from "../fmc.postpaid.types";
import { useUnpairFmc } from "../../../../shared/hooks/useUnpairFmc";
import { ChangeActivationType, DateTimeUtils } from "../../../../shared/utils/DateTimeUtils";
import { PackageUtils } from "../../../../shared/utils/Package.utils";
import { isRequestSuccess } from "../../../../shared/utils/utils";
import FmcPostpaidMessages from "../FmcPostpaid.messages";
import { DeactivatedPackages } from "./DeactivatedPackages";
import { errorActions, notificationActions, useStoreContext } from "../../../../shared/components/navigation/StepStateProvider";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";
import { hasGPONFTTBTag } from "../../../../shared/utils/acquisitionFlowUtils";
import { GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE } from "../../../../graphql/queries/associatedFttbSubscriptionType";


const Summary: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const state = useNavigationState<FmcPostpaidRouteState>();
	const intl = useIntl();
	const {currentDealerIdentety} = useAuthDataContext();
	const { unpairFmc } = useUnpairFmc();
	const [success, setSuccess] = useState<string[]>([] as string[]);
	const [fttbSuccess, setFttbSuccess] = useState<string>("");
	const [spinner, runSpinner] = useState(false);
	const {dispatch} = useStoreContext();

	const [createFttbOrder] = useMutation<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>(CREATE_FTTB_ORDER);
	const [createOrder, { loading }] = useMutation<Mutation, MutationCreate_Fmc_OrderArgs>(CREATE_FMC_ORDER);

	const [createPreOrder, { loading: preOrderLoading }] = useMutation<
	Required<Pick<Mutation, "create_subscription_preorder">>,
		MutationCreate_Subscription_PreorderArgs
	>(CREATE_SUBSCRIPTION_PREORDER);
	const {search, plan , preorder, addons } = state;

	const routerPackage = search?.fttbSubscription?.active_packages?.find(pack => pack?.code?.toLowerCase().includes("router"));

	const[hasGPONFTTB, setHasGPONFTTB] = useState(true);

	let postpaidType = "b2c";
	if (location.pathname === "/fmc-b2b-flow/summary") {
		postpaidType = "b2b";
	}

	const {data, loading: loading_associated_subscription } = useQuery<Required<Pick<Query, "associated_subscription_type"|"optional_products">>, QueryAssociated_Subscription_TypeArgs>(GET_ASSOCIATED_FTTB_SUBSCRIPTION_TYPE, {
		variables: {subscription_type_id: state?.plan?.selectedFmcTariffPlan?.id!},
		skip: !state?.plan?.selectedFmcTariffPlan?.id!,
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			setHasGPONFTTB(hasGPONFTTBTag(data?.optional_products!));
		}
	});
	
	useEffect(() => {
		if (routerPackage) {
			dispatch(notificationActions.setNotification("router"));
		}
	}, []);
	
	const [pollFttbSubscription, { stopPolling, variables }] = useLazyQuery<Required<Pick<Query, "limited_subscription">>, QueryLimited_SubscriptionArgs>(GET_FTTB_SUBSCRIPTION, {
		pollInterval: 2000,
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
		onCompleted: (data: Query) => {
			const newlyCreatedFttbSubscriptionId = data?.limited_subscription?.id;
			if (newlyCreatedFttbSubscriptionId
				&& search?.gsmSubscription?.id
				&& plan?.selectedFmcTariffPlan?.id
				&& plan?.selectedFmcTariffPlan?.selectedOptionId) {
				stopPolling();
				createPreOrder({
					variables: {
						details: {
							subscription_id: newlyCreatedFttbSubscriptionId,
							mobile_subscription_id: search.gsmSubscription?.id,
							subscription_type_id: plan.selectedFmcTariffPlan?.id,
							validity_of_preorder: moment()
								.add(30, "days")
								.format(config.DATE_PICKER_FORMAT),
							state: FmcPreOrderState.ACTIVE,
							packages: [plan?.selectedFmcTariffPlan?.selectedOptionId]
						},
					},
				}).then(({ data, errors }) => {
					if (
						errors ||
							data?.create_subscription_preorder?.error ||
							data?.create_subscription_preorder?.error_code
					) {
						throw intl.formatMessage(
							FMCMessages.preOrderFailed,
							{
								fttbMsisdn: variables.msisdn,
								fmcId: plan.selectedFmcTariffPlan?.id,
							}
						);
					}
					setSuccess([
						...success,
						intl.formatMessage(
							FMCMessages.preOrderCompleted,
							{
								fttbMsisdn: variables.msisdn,
								fmcId: plan.selectedFmcTariffPlan?.id,
							}
						),
					]);
				}).catch((errmsg) => {
					dispatch(errorActions.setError(errmsg));
				}).finally(() => {
					runSpinner(false);
				});
			}
		},
		onError: () => {
			stopPolling();
			runSpinner(false);
			dispatch(errorActions.setError("searchNewFttbSubscriptionFailed"));
		},
	});
	const fttbOptions: Array<string> = [];
	const {
		options,
		isToday,
		bundles,
	} = FttbService.addFttbFastIntoOptionsIfPhisicalWireDayIsToday(
		addons?.preorderOptions
	);

	const createOrderClick = () => {
		setSuccess([]);
		dispatch(errorActions.cleanError());

		setFttbSuccess("");
		if (search?.unpairFmc) {
			if (search.gsmSubscription?.id && plan?.selectedFmcTariffPlan?.code && currentDealerIdentety?.reseller_code) {
				unpairFmc({
					fmcSubscriptionId: search.gsmSubscription.id,
					date: DateTimeUtils.convertToBssActivationDate(ChangeActivationType.IMMEDIATE),
					subscriptionCode: plan?.selectedFmcTariffPlan.code,
					resellerCode: currentDealerIdentety?.reseller_code,
					options: PackageUtils.extractCodes(addons?.options),
				}).then(({data}) => {
					const status = data?.create_fmc_order?.status;
					if (status && isRequestSuccess(status)) {
						setSuccess([...success, intl.formatMessage({ ...FMCMessages.unpairSuccess })]);
					} else {
						dispatch(errorActions.setError("unpairFmcFailed"));
					}
				});
			} else {
				dispatch(errorActions.setError("unpairFmcFailed"));
			}
		} else if (search?.withoutFttb) {
			runSpinner(true);
			const fttbOptionGroupCode = addons?.preorderFttbTariffPlan?.option_groups?.[0]?.packages?.[0]?.code;
			fttbOptionGroupCode && options.push(fttbOptionGroupCode);
			preorder && addons && FttbService.handleFttbOrder(
				createFttbOrder,
				{
					msisdn: preorder.msisdn,
					housePreviousActivationStatus:
						preorder.housePreviousActivationStatus,
					contact_phone: preorder.contact_phone,
					first_name: preorder.first_name,
					last_name: preorder.last_name,
					middle_name: preorder.middle_name,
					confirmation_code: preorder.confirmationCode,
					selectedFttbTariffPlan: addons.preorderFttbTariffPlan,
					options: addons.preorderOptions,
					address: preorder.address,
				},
				intl,
				options,
				setFttbSuccess,
				runSpinner,
				currentDealerIdentety?.reseller_code
			).catch((error) => {
				dispatch(errorActions.setError(error));
				runSpinner(false);
				return FTTB_FAIL;
			}).then((errorCode) => {
				if (errorCode !== FTTB_FAIL && preorder.msisdn) {
					runSpinner(true);
					pollFttbSubscription({
						variables: {
							msisdn: preorder.msisdn,
						},
					});
				}
			}).catch(() => {
				runSpinner(false);
			});
		} else {
			const postpaid = true;
			FmcService.handleFmcOrder(
				createOrder,
				{...search, ...plan},
				fttbOptions,
				setSuccess,
				success,
				intl,
				dispatch,
				currentDealerIdentety?.reseller_code,
				postpaid,
				postpaidType
			);
		}
	};

	const noFttbLoading = spinner || loading || preOrderLoading;
	const showButton = useCallback(() => {
		return success.length > 0 ? (
			<button
				type="button"
				className="btn btn-primary"
				onClick={() => history.push(INDEX_PATH)}
			>
				<FormattedMessage {...CommonMessages.done} />
			</button>
		) : (
			<button
				type="button"
				onClick={createOrderClick}
				disabled={loading}
				className="btn btn-primary"
			>
				<FormattedMessage {...CommonMessages.order} />
			</button>
		);
	}, [loading, success]);
	const { values, setFieldValue, touched, handleChange, errors } = useFormik({
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmit: () => {},
		initialValues: {
			phone: preorder?.contact_phone || "",
			last_name: preorder?.last_name || "",
			first_name: preorder?.first_name || "",
			middle_name: preorder?.middle_name || "",
			...preorder?.address,
		},
		enableReinitialize: true,
	});

	const formik = { values, setFieldValue, touched, handleChange, errors };
	const MsisdnView = (msisdn) => <div className="col-12">
		<h4>
			<FormattedMessage {...FMCMessages.phone} />:&nbsp;
			{msisdn}
		</h4>
	</div>;

	return (
		<StepWrapper<FmcRouteState>
			isValid={true}>
			<div className="row">
				<div className="col-12">
					<h4>
						<FormattedMessage {...FMCMessages.FMC} />
					</h4>
					<div className="form-row mb-2">
						{/* <CustomerDetails
							owner={search?.gsmSubscription?.owner}
							msisdn={search?.gsmSubscription?.msisdn}/>
						<BillingAccountDetails
							lineType={search?.gsmSubscription?.subscription_type?.line_type}
							paymentType={search?.gsmSubscription?.payment_type}
							msisdn={search?.gsmSubscription?.msisdn}/> */}
					</div>
				</div>
				{MsisdnView(search?.gsmSubscription?.msisdn)}
				<div className="col-12">
					<PlanCard
						change_price={
								plan?.selectedFmcTariffPlan?.change_price
						}
						fee={plan?.selectedFmcTariffPlan?.fee}
						periodic_unit={
								plan?.selectedFmcTariffPlan?.periodicUnit
						}
						periodic_amount={
								plan?.selectedFmcTariffPlan?.periodicAmount
						}
						name={plan?.selectedFmcTariffPlan?.name}
						medium_description={
								plan?.selectedFmcTariffPlan?.medium_description
						}
						long_description={
								plan?.selectedFmcTariffPlan?.long_description
						}
					/>
				</div>
				{addons?.preorderFttbTariffPlan && (
					<>
						<div className="col-12 mt-2">
							<h4>
								<FormattedMessage
									{...FMCMessages.fttOrder}
								/>
							</h4>
						</div>
						{MsisdnView(preorder?.msisdn)}
						<div className="col-12">
							<FttbSummaryDetails
								bundles={bundles}
								simpleConnection={Boolean(preorder?.address?.simpleConnection)}
								options={addons.preorderOptions}
								isToday={isToday}
								formik={formik as any}
							/>
						</div>
					</>
				)}
				<div className="col-12 my-3">
					{ !addons?.preorderFttbTariffPlan && 
						<OptionsCard packages={addons?.preorderOptions?.packages}/>
					}
					{routerPackage && <OptionsCard packages={[routerPackage]}/>}
				</div>
				<div className="col-12">
					<h4>
						<FormattedMessage {...FmcPostpaidMessages.packagesToBeDeactivated} />
					</h4>
				</div>
				{search?.gsmSubscription?.msisdn && plan?.selectedFmcTariffPlan?.code
					&& <DeactivatedPackages 
						msisdn={search.gsmSubscription.msisdn}
						tariffPlanCode={plan.selectedFmcTariffPlan.code}/>}
				{!search?.withoutFttb && search?.fttbSubscription?.msisdn && plan?.selectedFmcTariffPlan?.code
					&& <DeactivatedPackages 
						msisdn={search.fttbSubscription.msisdn}
						tariffPlanCode={plan.selectedFmcTariffPlan.code}/>}
				<div className="col-12">
					{(loading || noFttbLoading) && <CircularProgress />}
					<SuccessMessageList
						successMsgs={
							fttbSuccess
								? [...success, fttbSuccess]
								: success
						}
					/>
				</div>
				<div className="col-12">
					<div className="text-right">{showButton()}</div>
				</div>
			</div>
		</StepWrapper>
	);
};

export { Summary };
