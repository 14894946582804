import gql from "graphql-tag";
import { Customer } from "../fragments/customer.fragments";

//TODO UKR-16716
export const GET_EXISTING_CUSTOMER = gql`
    query getExistingCustomers($id_number: String, $id_type: String, $individual_tax_number: ID) {
       customers(id_number: $id_number, id_type: $id_type, individual_tax_number: $individual_tax_number) {
          ...Customer
       }
    }
    ${Customer}
`;

export const GET_CUSTOMER_IDENTIFICATION = gql`
    query getCustomerIdentification($msisdn: String!) {
      customer_identification(msisdn: $msisdn) {
         status
      }
    }
`;
