export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
  Decimal: any;
  Date: any;
  Time: any;
  Array: any;
  Datetime: any;
  Upload: any;
};






export enum AccountLegalAddressAttributesEnum {
  Primary = "primary",
  Home = "home",
  Work = "work",
  Billing = "billing",
  Delivery = "delivery",
  Marketing = "marketing",
  Installation = "installation"
}

export type Address = {
  __typename?: "Address";
  co_address?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["JSONObject"]>;
  house_number?: Maybe<Scalars["String"]>;
  building?: Maybe<Scalars["String"]>;
  floor?: Maybe<Scalars["String"]>;
  apartment?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
  po_box?: Maybe<Scalars["String"]>;
  postal_district?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["JSONObject"]>;
  county?: Maybe<Scalars["JSONObject"]>;
  province?: Maybe<Scalars["JSONObject"]>;
  country?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["JSONObject"]>;
  type?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  /** Comments for address. */
  comments?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["JSONObject"]>;
  gis_house_id?: Maybe<Scalars["String"]>;
  gis_city_id?: Maybe<Scalars["String"]>;
  gis_street_id?: Maybe<Scalars["String"]>;
  gis_area_id?: Maybe<Scalars["String"]>;
  gis_country_id?: Maybe<Scalars["String"]>;
  gis_district_id?: Maybe<Scalars["String"]>;
  gis_region_id?: Maybe<Scalars["String"]>;
  gis_zip_id?: Maybe<Scalars["String"]>;
  address_type?: Maybe<Scalars["String"]>;
  house_unverified?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  co_address?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["String"]>;
  building?: Maybe<Scalars["String"]>;
  floor?: Maybe<Scalars["String"]>;
  apartment?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
  po_box?: Maybe<Scalars["String"]>;
  postal_district?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  /** Comments for address. */
  comments?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["String"]>;
};

export type AddressToWaitingList = {
  channel: CmsChannelEnum;
  customer_name: Scalars["String"];
  customer_contact_phone: Scalars["String"];
  building_gis_id: Scalars["Int"];
  apartment_number: Scalars["String"];
  customer_surname: Scalars["String"];
  customer_middle_name: Scalars["String"];
  /** msisdn of fttb order */
  link_request_id?: Maybe<Scalars["String"]>;
  rate_plan: RatePlan;
};


export type Attributes = {
  __typename?: "Attributes";
  confirmation_code?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
  reserve_valid_for?: Maybe<Scalars["String"]>;
};

export type Balance = {
  __typename?: "Balance";
  dedicated_account_id?: Maybe<Scalars["Int"]>;
  balance_name?: Maybe<Scalars["String"]>;
  balance_commercial_name?: Maybe<Language>;
  amount?: Maybe<Scalars["Float"]>;
  total_amount?: Maybe<Scalars["Float"]>;
  unit?: Maybe<Scalars["String"]>;
  expiry_at?: Maybe<Scalars["String"]>;
  unlimited?: Maybe<Scalars["Boolean"]>;
  service_type?: Maybe<Scalars["String"]>;
  is_main_balance?: Maybe<Scalars["Boolean"]>;
};

export type Barring = {
  __typename?: "Barring";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Language>;
  barring_level_codes?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BillingAccount = {
  __typename?: "BillingAccount";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  invoice_delivery_method?: Maybe<Scalars["String"]>;
  payment_method?: Maybe<Scalars["String"]>;
  owner?: Maybe<Customer>;
  payer?: Maybe<Customer>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<BillingAccountType>;
  billing_reports?: Maybe<Array<Maybe<BillingAccountBillingReport>>>;
  balances?: Maybe<Array<Maybe<BillingAccountBalance>>>;
  /** Active barrings under billing account */
  barrings?: Maybe<Array<Maybe<Barring>>>;
};

export type BillingAccountBalance = {
  __typename?: "BillingAccountBalance";
  /** Billing account balance amount */
  amount?: Maybe<Scalars["Decimal"]>;
  /** Billing account balance total amount */
  total_amount?: Maybe<Scalars["Decimal"]>;
  /** Billing account total balance */
  total_balance?: Maybe<Scalars["Decimal"]>;
  /** Billing account balance currency */
  unit?: Maybe<Scalars["String"]>;
};

export type BillingAccountBillingReport = {
  __typename?: "BillingAccountBillingReport";
  /** Billing account debt */
  debt?: Maybe<Scalars["Decimal"]>;
  /** Billing account main balance */
  main_balance?: Maybe<Scalars["Decimal"]>;
  /** Billing account status */
  billing_account_status?: Maybe<Scalars["String"]>;
};

export type BillingAccountInput = {
  type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  delivery_zone?: Maybe<Scalars["String"]>;
};

export type BillingAccountType = {
  __typename?: "BillingAccountType";
  /** Billinging account type code */
  code?: Maybe<Scalars["String"]>;
  /** Billing account currency */
  currency?: Maybe<Scalars["String"]>;
  /** Is default billing account */
  is_default?: Maybe<Scalars["Boolean"]>;
  /** Billing account type name */
  name?: Maybe<Scalars["String"]>;
};

export type BillingReport = {
  __typename?: "BillingReport";
  billing_account_id?: Maybe<Scalars["ID"]>;
  debt?: Maybe<Scalars["Float"]>;
  credit_limit?: Maybe<Scalars["Float"]>;
  credit_limit_usage?: Maybe<Scalars["Float"]>;
  over_payment?: Maybe<Scalars["Float"]>;
  advance_payment?: Maybe<Scalars["Float"]>;
  unbilled?: Maybe<Scalars["Float"]>;
  deposits?: Maybe<Deposit>;
};

export type CallDetailRecord = {
  __typename?: "CallDetailRecord";
  subscription_id?: Maybe<Scalars["ID"]>;
  bnr?: Maybe<Scalars["String"]>;
  call_direction?: Maybe<Scalars["String"]>;
  call_destination_type?: Maybe<Scalars["String"]>;
  calling_number?: Maybe<Scalars["String"]>;
  called_number?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  event_at?: Maybe<Scalars["String"]>;
  roaming_indicator?: Maybe<Scalars["Boolean"]>;
  transaction_type?: Maybe<Scalars["String"]>;
  data_amount?: Maybe<Scalars["Int"]>;
  balances?: Maybe<Array<Maybe<RecordBalance>>>;
};

export type CampaignOffer = {
  __typename?: "CampaignOffer";
  offer_id?: Maybe<Scalars["String"]>;
  offer_type_name?: Maybe<Scalars["String"]>;
  offer_name?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  offer_short_description?: Maybe<Scalars["String"]>;
  offer_code?: Maybe<Scalars["String"]>;
  offer_long_description?: Maybe<Scalars["String"]>;
  extra_info?: Maybe<Scalars["String"]>;
  offer_category_name?: Maybe<Scalars["String"]>;
  action_type_name?: Maybe<Scalars["String"]>;
};

export type CasResult = {
  __typename?: "CasResult";
  result: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
};

export type CatalogSimCard = {
  __typename?: "CatalogSimCard";
  card_type?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  is_in_stock?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CdtConsumerOrdererAccount = {
  id: Scalars["String"];
  /** Type is always 'customer-accounts' */
  type?: Maybe<Scalars["String"]>;
  attributes?: Maybe<ConsumerOrdererAccountAttributes>;
};

export type CdtConsumerOrdererIdentification = {
  /** ID of the identifications entity. */
  id: Scalars["String"];
  /** Always 'identifications' */
  type?: Maybe<Scalars["String"]>;
  attributes?: Maybe<ConsumerScreeningOrdererIdentificationAttributes>;
};

export type CdtConsumerScreeningOrderer = {
  type: Scalars["String"];
  id: Scalars["String"];
  attributes?: Maybe<ConsumerScreeningOrdererAttributes>;
};

export type CdtCreditDecisionResponse = {
  __typename?: "CDTCreditDecisionResponse";
  id?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  credit_decision?: Maybe<CdtResponseEnum>;
  credit_profile_proposal_id?: Maybe<Scalars["String"]>;
  allowed_payment_methods?: Maybe<Scalars["String"]>;
  allowed_bill_payment_methods?: Maybe<Scalars["Array"]>;
  business_instructions?: Maybe<Scalars["Array"]>;
  customer_instructions?: Maybe<Scalars["Array"]>;
  errors?: Maybe<Scalars["Array"]>;
};

export type CdtOrdererNewResidentialCreditInput = {
  customer_id?: Maybe<Scalars["String"]>;
  first_name: Scalars["String"];
  last_name: Scalars["String"];
  nationality: Scalars["String"];
  salutation?: Maybe<SalutationEnum>;
  language?: Maybe<Scalars["String"]>;
  employment_type?: Maybe<Scalars["String"]>;
  /** Date is of format %Y-%m-%d */
  date_of_birth: Scalars["Date"];
  email?: Maybe<Scalars["String"]>;
  id_document_type?: Maybe<IdTypeEnum>;
  id_document_number: Scalars["String"];
  legal_address_street_name: Scalars["String"];
  legal_address_street_number?: Maybe<Scalars["String"]>;
  legal_address_zip: Scalars["String"];
  legal_address_city: Scalars["String"];
  legal_address_country: Scalars["String"];
  /** Date is of format %Y-%m-%d */
  legal_address_start_date?: Maybe<Scalars["Date"]>;
  billing_address_name: Scalars["String"];
  billing_address_street_name: Scalars["String"];
  billing_address_street_number?: Maybe<Scalars["String"]>;
  billing_address_zip: Scalars["String"];
  billing_address_city: Scalars["String"];
  billing_address_country: Scalars["String"];
  customer_category: CustomerCategoryEnum;
};

export type CdtOrderInput = {
  /** The order's unique id. */
  id?: Maybe<Scalars["String"]>;
  brand: Scalars["String"];
  order_type: OrderTypeEnum;
  /**
   * If some of the order items include a subscription which will undergo the MNP process,
   * put this to true.
   */
  includes_mnp?: Maybe<Scalars["Boolean"]>;
  /**
   * Order creation timestamp.
   * example: 2020-10-10T12:00:00Z
   */
  created_at: Scalars["String"];
  delivery_address_name: Scalars["String"];
  delivery_address_co?: Maybe<Scalars["String"]>;
  delivery_address_street_name: Scalars["String"];
  delivery_address_street_number?: Maybe<Scalars["String"]>;
  delivery_address_zip: Scalars["String"];
  delivery_address_city: Scalars["String"];
  /** Value should be ISO 3166-1-alpha-2. */
  delivery_address_country: Scalars["String"];
};

export enum CdtProductTypeEnum {
  Option = "OPTION",
  Other = "OTHER",
  PostpaidSubscription = "POSTPAID_SUBSCRIPTION",
  PrepaidSubscription = "PREPAID_SUBSCRIPTION",
  PostpaidWithPrepaymentSubscription = "POSTPAID_WITH_PREPAYMENT_SUBSCRIPTION",
  Terminal = "TERMINAL"
}

export enum CdtResponseEnum {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED"
}

export type CdtSalesinfo = {
  reference_number?: Maybe<Scalars["String"]>;
  sales_brand?: Maybe<Scalars["String"]>;
  sales_type?: Maybe<OrderTypeEnum>;
  origin_requested_at?: Maybe<Scalars["String"]>;
  origin_customer_ip?: Maybe<Scalars["String"]>;
  origin_channel_ip?: Maybe<Scalars["String"]>;
};

export type CdtScreeningResponse = {
  __typename?: "CdtScreeningResponse";
  type?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  attributes?: Maybe<CdtScreeningResponseAttribute>;
  errors?: Maybe<Scalars["Array"]>;
};

export type CdtScreeningResponseAttribute = {
  __typename?: "CdtScreeningResponseAttribute";
  id?: Maybe<Scalars["Int"]>;
  screening_decision?: Maybe<CdtResponseEnum>;
};

export type ChildOffer = {
  __typename?: "ChildOffer";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Language>;
  activation_renewal_template_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  activation_template_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  allow_re_activation?: Maybe<Scalars["String"]>;
  charge_type?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  deactivation_template_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  display_order?: Maybe<Scalars["Int"]>;
  external_kb_link?: Maybe<Scalars["String"]>;
  info_text?: Maybe<Language>;
  is_configurable?: Maybe<Scalars["Boolean"]>;
  long_description?: Maybe<Language>;
  long_info_text?: Maybe<Language>;
  medium_description?: Maybe<Language>;
  modifications_template_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  periodic_amount?: Maybe<Scalars["Int"]>;
  periodic_unit?: Maybe<Scalars["String"]>;
  renewal_warning_template_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  short_description?: Maybe<Language>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  ussd_code?: Maybe<Scalars["String"]>;
  fee?: Maybe<Scalars["Float"]>;
};

export enum CmsChannelEnum {
  HelpDesk = "helpDesk",
  Site = "site",
  AppForSellers = "app_for_sellers",
  Dealer = "dealer"
}

export type CmsError = {
  __typename?: "CMSError";
  code?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
};

export type CmsResponse = {
  __typename?: "CMSResponse";
  requestId?: Maybe<Scalars["String"]>;
  error?: Maybe<CmsError>;
};

export type CompatiblePackage = {
  __typename?: "CompatiblePackage";
  name?: Maybe<Language>;
  code?: Maybe<Scalars["String"]>;
  package_product_type?: Maybe<Scalars["String"]>;
};

export type CompatibleProducts = {
  __typename?: "CompatibleProducts";
  always_deactivated_packages?: Maybe<Array<Maybe<CompatiblePackage>>>;
  always_saved_packages?: Maybe<Array<Maybe<CompatiblePackage>>>;
  current_subscription_type?: Maybe<Scalars["String"]>;
  packages_to_be_activated?: Maybe<Array<Maybe<CompatiblePackage>>>;
  packages_to_be_deactivated?: Maybe<Array<Maybe<CompatiblePackage>>>;
  target_subscription_type?: Maybe<Scalars["String"]>;
};

export enum ConnectionTypeEnum {
  SecondGen = "SECOND_GEN",
  ThirdGen = "THIRD_GEN",
  FourthGen = "FOURTH_GEN"
}

export type ConsumerOrdererAccountAttributes = {
  /**
   * Identifier that is known to the customer. It is used in customer communication,
   * it may be printed on customer letters or invoices. Its value driven by the underlaying
   * BSS implementation, usually follows a numbering schema depending on the value of the
   * account-type attribute.
   */
  account_id: Scalars["String"];
  /** Account type as defined in BSSAPI Customer Account. */
  account_type?: Maybe<Scalars["String"]>;
};

export type ConsumerScreeningOrdererAttributes = {
  given_name: Scalars["String"];
  family_name: Scalars["String"];
  formatted_name?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  honorific_prefix?: Maybe<SalutationEnum>;
  language?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
};

/**
 * BSSAPI Identification See: (BSSAPI Identification)
 * [https://api.qvantel.com/reference/15.11/releases/15.11.94+1-ref/public/#identifications]
 */
export type ConsumerScreeningOrdererIdentificationAttributes = {
  /**
   * Identifier that is together with the identification-type shall uniquely identify
   * the ID document/paper, such as the social security number, business code,
   * passport number, etc.
   */
  identification_id: Scalars["String"];
  /**
   * Type of identification that together with the identification-id shall uniquely
   * identify the ID paper/document. Common types are for example social security number,
   * business code, work permit, passport, driver's license, student visa, etc.
   * The list of recognized identification types (if restricted) is present via enumeration.
   */
  identification_type: IdTypeEnum;
  /**
   * Valid for Datetime of BSSAPI Complex data type to represent
   * validity periods with required start date-time and optional end date-time.
   * See (BSSAPI Valid For Datetime)
   * [https://api.qvantel.com/reference/15.11/releases/15.11.94+1-ref/public/#valid-for-datetime]
   */
  valid_for?: Maybe<Validfor>;
};

export type Customer = {
  __typename?: "Customer";
  id?: Maybe<Scalars["ID"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  is_company?: Maybe<Scalars["Boolean"]>;
  billing_accounts?: Maybe<Array<Maybe<BillingAccount>>>;
  /** List of invoices under customer */
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  /** Any ongoing or scheduled changes (Memos) a customer has. */
  ongoing_changes?: Maybe<Array<Maybe<Memo>>>;
  id_document_type?: Maybe<Scalars["String"]>;
  id_document_number?: Maybe<Scalars["String"]>;
  id_document_issued_by?: Maybe<Scalars["String"]>;
  id_expiry?: Maybe<Scalars["String"]>;
  id_document_issue_date?: Maybe<Scalars["String"]>;
  additional_id_date?: Maybe<Scalars["String"]>;
  contact_phone?: Maybe<Scalars["String"]>;
  /** Also dtermined as individual_tax_id */
  tax_id?: Maybe<Scalars["String"]>;
  middle_name?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  is_anonymous_customer?: Maybe<Scalars["Boolean"]>;
  address?: Maybe<Array<Maybe<Address>>>;
};


export type CustomerInvoicesArgs = {
  status?: Maybe<Scalars["String"]>;
};

export enum CustomerCategoryEnum {
  Own = "OWN",
  Business = "BUSINESS",
  Consumer = "CONSUMER"
}

export type CustomerIdentification = {
  __typename?: "CustomerIdentification";
  created_at?: Maybe<Scalars["Datetime"]>;
  csr_agent_code?: Maybe<Scalars["String"]>;
  question_id?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  unsuccesful_attempts?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
};

export type CustomerInput = {
  additional_emails?: Maybe<Scalars["String"]>;
  /** Additional document date. */
  additional_id_date?: Maybe<Scalars["String"]>;
  apsid?: Maybe<Scalars["Int"]>;
  collector_id?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contact_phone?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  first_name_alt?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  id_doc_issue_date?: Maybe<Scalars["Date"]>;
  id_doc_issued_by?: Maybe<Scalars["String"]>;
  id_doc_valid_till_date?: Maybe<Scalars["Date"]>;
  id_document_number?: Maybe<Scalars["String"]>;
  id_document_type?: Maybe<Scalars["String"]>;
  individual_tax_number?: Maybe<Scalars["String"]>;
  invoice_delivery_type?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  last_name_alt?: Maybe<Scalars["String"]>;
  legal_address?: Maybe<LegalAddressInput>;
  marketing_allowed_own?: Maybe<Scalars["Boolean"]>;
  marketing_allowed_third_party?: Maybe<Scalars["Boolean"]>;
  middle_name?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  occupation?: Maybe<Scalars["String"]>;
  online_id?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  payment_method?: Maybe<Scalars["String"]>;
};




export enum DeliveryTypeEnum {
  Direct = "direct",
  Logistics = "logistics",
  Activate = "activate"
}

export type Deposit = {
  __typename?: "Deposit";
  default?: Maybe<Scalars["Float"]>;
};

export enum EmployementTypeEnum {
  FullTime = "FULL_TIME",
  PartTime = "PART_TIME",
  SelfEmployed = "SELF_EMPLOYED",
  Retired = "RETIRED",
  HousemanHousewife = "HOUSEMAN_HOUSEWIFE",
  Unemployed = "UNEMPLOYED",
  Student = "STUDENT",
  Other = "OTHER",
  NotGiven = "NOT_GIVEN"
}

export type EventDetailRecord = {
  __typename?: "EventDetailRecord";
  subscription_id?: Maybe<Scalars["ID"]>;
  msisdn?: Maybe<Scalars["String"]>;
  edr_type_label?: Maybe<Scalars["String"]>;
  event_at?: Maybe<Scalars["String"]>;
  balances?: Maybe<Array<Maybe<RecordBalance>>>;
};

export type Fee = {
  __typename?: "Fee";
  fee?: Maybe<Scalars["Float"]>;
  fee_type?: Maybe<Scalars["String"]>;
  tax_inclusive?: Maybe<Scalars["Boolean"]>;
};

export enum FttbConnectionTypeEnum {
  Normal = "normal",
  Simple = "simple"
}

export type FttbGigabitStatus = {
  __typename?: "FTTBGigabitStatus";
  status?: Maybe<Scalars["Boolean"]>;
};

export type GisCity = {
  __typename?: "GISCity";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  available_streets?: Maybe<Array<Maybe<GisStreet>>>;
};


export type GisCityAvailable_StreetsArgs = {
  street_name?: Maybe<Scalars["String"]>;
};

export type GisCountry = {
  __typename?: "GISCountry";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  available_regions?: Maybe<Array<Maybe<GisRegion>>>;
  available_cities?: Maybe<Array<Maybe<GisCity>>>;
};


export type GisCountryAvailable_RegionsArgs = {
  region_name?: Maybe<Scalars["String"]>;
};


export type GisCountryAvailable_CitiesArgs = {
  city_name?: Maybe<Scalars["String"]>;
};

export type GisDistrict = {
  __typename?: "GISDistrict";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type GisFlat = {
  __typename?: "GISFlat";
  id?: Maybe<Scalars["ID"]>;
  flat_number?: Maybe<Scalars["String"]>;
  fttb_available?: Maybe<Scalars["Boolean"]>;
  house_id?: Maybe<Scalars["String"]>;
  is_1g_available?: Maybe<Scalars["Boolean"]>;
};

export type GisHouse = {
  __typename?: "GISHouse";
  id?: Maybe<Scalars["ID"]>;
  street_id?: Maybe<Scalars["ID"]>;
  house_number?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["String"]>;
  zip_code?: Maybe<Scalars["String"]>;
  zip_id?: Maybe<Scalars["String"]>;
  fttb_available?: Maybe<Scalars["Boolean"]>;
  network_type_id?: Maybe<Scalars["String"]>;
  green_field?: Maybe<GisHouseGreenfield>;
};


export type GisHouseGreen_FieldArgs = {
  apartment?: Maybe<Scalars["String"]>;
};

export type GisHouseGreenfield = {
  __typename?: "GISHouseGreenfield";
  apartment?: Maybe<Scalars["String"]>;
  gis_house_id?: Maybe<Scalars["ID"]>;
  green_field_status?: Maybe<Scalars["Int"]>;
  green_field_tp_status?: Maybe<Scalars["Int"]>;
  green_field_tp?: Maybe<Array<Maybe<Scalars["String"]>>>
};

export type GisHousePreviousActivationStatus = {
  __typename?: "GISHousePreviousActivationStatus";
  /**
   * A. If there are recodrs of FTTB connection → Address was in use: Return 0
   * B. If there is a record with fttb_deactivation_date=null → Connection still exists: Return 2
   * C. Otherwise (no records) : Return 1
   *
   * `A` & `B` are considered for simple connections and `C` is considered for normal connection
   */
  previous_activation_status?: Maybe<Scalars["Int"]>;
  msisdn_with_max_last_rc_date?: Maybe<Scalars["String"]>;
};

export type GisRegion = {
  __typename?: "GISRegion";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  available_districts?: Maybe<Array<Maybe<GisDistrict>>>;
};


export type GisRegionAvailable_DistrictsArgs = {
  district_name?: Maybe<Scalars["String"]>;
};

export type GisStreet = {
  __typename?: "GISStreet";
  id?: Maybe<Scalars["ID"]>;
  city_id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["String"]>;
  available_houses?: Maybe<Array<Maybe<GisHouse>>>;
};


export type GisStreetAvailable_HousesArgs = {
  house_number?: Maybe<Scalars["String"]>;
};

export enum IdTypeEnum {
  Passport = "passport",
  DrivingLicense = "driving_license",
  ResidencePermit = "residence_permit",
  PassportKgExternal = "passport_kg_external",
  PensionCertificate = "pension_certificate",
  OfficerCertificate = "officer_certificate",
  MilitaryTicket = "military_ticket",
  RefugeeCertificate = "refugee_certificate",
  CertificateRegistration = "certificate_registration",
  ForeignPassport = "foreign_passport",
  PassportForeign = "passport_foreign",
  NationalId = "national_id",
  DiplomaticPassportOfUkraine = "diplomatic_passport_of_ukraine",
  ServicePassportsOfUkraine = "service_passports_of_ukraine",
  SeafarersIdentityCard = "seafarers_identity_card",
  CertificateOfTheCrewMember = "certificate_of_the_crew_member",
  IdCardForReturnToUkraine = "id_card_for_return_to_ukraine",
  TemporaryCertificateOfACitizenOfUkraine = "temporary_certificate_of_a_citizen_of_ukraine",
  CertificateOfAStatelessPersonToTravelAbroad = "certificate_of_a_stateless_person_to_travel_abroad",
  PermanentResidencePermit = "permanent_residence_permit",
  MigrantCard = "migrant_card",
  RefugeeTravelDocument = "refugee_travel_document",
  IdCardThatNeedsAdditionalProtection = "ID_card_that_needs_additional_protection",
  TravelDocumentOfAPersonWhoHasBeenGrantedAdditionalProtection = "travel_document_of_a_person_who_has_been_granted_additional_protection",
  PassportDocumentOfAnotherCountry = "passport_document_of_another_country",
  Edrpou = "EDRPOU"
}

export type Invoice = {
  __typename?: "Invoice";
  invoice_id?: Maybe<Scalars["ID"]>;
  invoice_external_id?: Maybe<Scalars["String"]>;
  total_without_vat?: Maybe<Scalars["Float"]>;
  total?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
  invoice_date?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["String"]>;
  pdf?: Maybe<Scalars["String"]>;
  claim_state?: Maybe<Scalars["String"]>;
  paid_amount?: Maybe<Scalars["Float"]>;
  payment_status?: Maybe<Scalars["String"]>;
};


export type Jwt = {
  __typename?: "JWT";
  username?: Maybe<Scalars["String"]>;
  /** Permissiong groups a user belongs to. To be deprecated in favour of `permissions` */
  permission_groups?: Maybe<Array<Maybe<UserPermissionGroup>>>;
  /** Single permissions a user has. To be deprecated in favour of `permissions` */
  user_permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** All permissions for a user. */
  permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  dealer_identities?: Maybe<Array<Maybe<UserDealerIdentity>>>;
};

export type Language = {
  __typename?: "Language";
  en?: Maybe<Scalars["String"]>;
  bn?: Maybe<Scalars["String"]>;
  ru?: Maybe<Scalars["String"]>;
  ky?: Maybe<Scalars["String"]>;
  uz?: Maybe<Scalars["String"]>;
  uk?: Maybe<Scalars["String"]>;
};

export enum LanguageEnum {
  En = "en",
  Bn = "bn",
  Ru = "ru",
  Ky = "ky",
  Uz = "uz",
  Uk = "uk"
}

export type LegalAddressInput = {
  attributes: AddressInput;
};

export type LimitedSubscription = {
  __typename?: "LimitedSubscription";
  id?: Maybe<Scalars["ID"]>;
  is_portout_ongoing?: Maybe<Scalars["Boolean"]>;
  is_starterkit?: Maybe<Scalars["Boolean"]>;
  subscription_exists?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  sim_card?: Maybe<SimCard>;
  is_prepaid?: Maybe<Scalars["Boolean"]>;
};

export enum LineTypeEnum {
  Mobile = "mobile",
  Fixed = "fixed",
  Fttb = "fttb",
  Fmc = "fmc"
}

export enum LiveSettingEnum {
  CsrtbLink = "csrtb_link",
  SimIccPrefix = "sim_icc_prefix",
  WttxConnectionUrl = "WTTX_connection_url"
}

export type LiveSettingResponse = {
  __typename?: "LiveSettingResponse";
  value?: Maybe<Scalars["String"]>;
  value_type?: Maybe<Scalars["String"]>;
};

export type Memo = {
  __typename?: "Memo";
  id?: Maybe<Scalars["ID"]>;
  status?: Maybe<Scalars["String"]>;
  action_state?: Maybe<Scalars["String"]>;
  handler_name?: Maybe<Scalars["String"]>;
};

export type MnpOrderInput = {
  document_id: Scalars["String"];
  /** MSISDN to be ported from foregin operator.  */
  msisdn: Scalars["String"];
  /** Internal MSISDN could be starter kit or existing active subscription.  */
  temp_msisdn?: Maybe<Scalars["String"]>;
  /**
   * Approximate trasfer time of portability agreed with customer.
   *
   * example format: 2020-07-30T13:44:33Z
   */
  transfer_time: Scalars["String"];
  /**
   * Foregin network operator name
   *
   * example: vodafone.
   */
  current_operator: Scalars["String"];
  /** Is temp_msisdn belongs to starter kit. */
  starter_kit?: Maybe<Scalars["Boolean"]>;
  /** Check to verify if contract is signed.  */
  contract_signed?: Maybe<Scalars["Boolean"]>;
  user_passportized?: Maybe<PassportizedEnum>;
  /** donar information */
  donor_segment: Scalars["String"];
  donor_first_name?: Maybe<Scalars["String"]>;
  donor_last_name?: Maybe<Scalars["String"]>;
  donor_passport_id_card?: Maybe<Scalars["String"]>;
  donor_inn?: Maybe<Scalars["String"]>;
  donor_okpo?: Maybe<Scalars["String"]>;
};

export type Msisdn = {
  __typename?: "Msisdn";
  price?: Maybe<Scalars["Float"]>;
  msisdn?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  number_category?: Maybe<Scalars["Int"]>;
};

export type MsisdnInput = {
  msisdn: Scalars["String"];
};

/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type Mutation = {
  __typename?: "Mutation";
  login?: Maybe<Token>;
  refresh_token?: Maybe<Token>;
  logout?: Maybe<Response>;
  reset_password?: Maybe<Response>;
  reset_password_confirmation?: Maybe<Response>;
  change_password?: Maybe<Response>;
  /**
   * Screening is an operation to check customer eligibility without knowing
   * any details of the upcoming order. Usually used for e.g. blacklist checks and
   * such to prevent orders from even being submitted by customers or for example blacklisted employees.
   */
  check_cdt_consumer_screening?: Maybe<CdtScreeningResponse>;
  /**
   * Credit decision is more complex operation which can take into account also order value
   * and customer credit score indicated by a third party service provider.
   */
  check_cdt_consumer_credit_decision?: Maybe<CdtCreditDecisionResponse>;
  edit_customer?: Maybe<Response>;
  reserve_msisdn?: Maybe<Response>;
  /** DEPRECATED. Old order mutation for every order type. Please use create_<order-type>_order instead. */
  create_order?: Maybe<Response>;
  /** Create acquisition order */
  create_acquisition_order?: Maybe<Response>;
  /** Create retention order */
  create_retention_order?: Maybe<Response>;
  /** Create MNP order */
  create_mnp_order?: Maybe<Response>;
  /** Create FTTB order */
  create_fttb_order?: Maybe<Response>;
  create_fmc_order?: Maybe<Response>;
  upload_acquisition_document?: Maybe<Response>;
  add_package?: Maybe<Response>;
  remove_package?: Maybe<Response>;
  add_barring?: Maybe<Response>;
  remove_barring?: Maybe<Response>;
  add_service?: Maybe<Response>;
  remove_service?: Maybe<Response>;
  change_msisdn?: Maybe<Response>;
  change_sim?: Maybe<Response>;
  activate_subscription?: Maybe<Response>;
  dealer_balance_transfer?: Maybe<Response>;
  change_subscription_type?: Maybe<Response>;
  personalize_for_existing_customer?: Maybe<Response>;
  upload_document?: Maybe<Response>;
  campaign_offer_action?: Maybe<Response>;
  document_uid_number_reservations?: Maybe<Response>;
  billing_account_id_reservations?: Maybe<Response>;
  encrypt_cookie?: Maybe<Scalars["String"]>;
  decrypt_cookie?: Maybe<Token>;
  /** Request OTP to subscription MSISDN or subscriber's contact phone if FTTB. */
  cas_request_otp?: Maybe<CasResult>;
  create_subscription_preorder?: Maybe<Response>;
  /** Method to send request to BSS API to cancel subscription preorder */
  cancel_subscription_preorder?: Maybe<Response>;
  /**
   * Method to send request to CMS for adding address to
   * waiting list for FTTB connection.
   */
  fttb_add_address_to_waiting_list?: Maybe<CmsResponse>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationLoginArgs = {
  username: Scalars["String"];
  password: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationRefresh_TokenArgs = {
  refresh_token: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationLogoutArgs = {
  refresh_token?: Maybe<Scalars["String"]>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationReset_PasswordArgs = {
  email: Scalars["String"];
  domain: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationReset_Password_ConfirmationArgs = {
  username: Scalars["String"];
  timestamp: Scalars["String"];
  hash: Scalars["String"];
  new_password?: Maybe<Scalars["String"]>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationChange_PasswordArgs = {
  username: Scalars["String"];
  old_password: Scalars["String"];
  new_password: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCheck_Cdt_Consumer_ScreeningArgs = {
  sales_info?: Maybe<CdtSalesinfo>;
  orderer: CdtConsumerScreeningOrderer;
  orderer_identification: CdtConsumerOrdererIdentification;
  orderer_account: CdtConsumerOrdererAccount;
  legal_postal_address?: Maybe<OrdererAccountLegalAddress>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCheck_Cdt_Consumer_Credit_DecisionArgs = {
  orderer: CdtOrdererNewResidentialCreditInput;
  order: CdtOrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationEdit_CustomerArgs = {
  customer_id: Scalars["ID"];
  details: CustomerInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationReserve_MsisdnArgs = {
  msisdn?: Maybe<Scalars["String"]>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_OrderArgs = {
  details: OrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Acquisition_OrderArgs = {
  details: OrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Retention_OrderArgs = {
  details: OrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Mnp_OrderArgs = {
  details: OrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Fttb_OrderArgs = {
  details: OrderInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Fmc_OrderArgs = {
  details: OrderFmcInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationUpload_Acquisition_DocumentArgs = {
  details: UploadAcquisitionDocument;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationAdd_PackageArgs = {
  subscription_id: Scalars["ID"];
  package: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationRemove_PackageArgs = {
  subscription_id: Scalars["ID"];
  package: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationAdd_BarringArgs = {
  subscription_id: Scalars["ID"];
  barring: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationRemove_BarringArgs = {
  subscription_id: Scalars["ID"];
  barring: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationAdd_ServiceArgs = {
  subscription_id: Scalars["ID"];
  service: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationRemove_ServiceArgs = {
  subscription_id: Scalars["ID"];
  service: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationChange_MsisdnArgs = {
  subscription_id: Scalars["ID"];
  old_msisdn: Scalars["String"];
  new_msisdn: Scalars["String"];
  reason: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationChange_SimArgs = {
  subscription_id: Scalars["ID"];
  old_icc: Scalars["String"];
  new_icc: Scalars["String"];
  reason: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationActivate_SubscriptionArgs = {
  subscription_id: Scalars["ID"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationDealer_Balance_TransferArgs = {
  target_msisdn: Scalars["String"];
  amount: Scalars["Int"];
  currency: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationChange_Subscription_TypeArgs = {
  subscription_id: Scalars["ID"];
  type_id: Scalars["String"];
  new_packages?: Maybe<Array<Maybe<PackageInput>>>;
  send_sms: Scalars["Boolean"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationPersonalize_For_Existing_CustomerArgs = {
  subscription_id: Scalars["ID"];
  customer_id: Scalars["ID"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationUpload_DocumentArgs = {
  details: UploadDocument;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCampaign_Offer_ActionArgs = {
  subscription_id: Scalars["ID"];
  offer_id: Scalars["String"];
  action_type_name: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationEncrypt_CookieArgs = {
  auth_token: TokenInput;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationDecrypt_CookieArgs = {
  auth_cookie?: Maybe<Scalars["String"]>;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCas_Request_OtpArgs = {
  msisdn: Scalars["String"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCreate_Subscription_PreorderArgs = {
  details: Preorder;
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationCancel_Subscription_PreorderArgs = {
  preorder_id?: Maybe<Scalars["Int"]>;
  subscription_id: Scalars["Int"];
};


/**
 * Root for all types.
 *
 * Types need to be declared here and be extended in schemas
 * if used in multiple files.
 */
export type MutationFttb_Add_Address_To_Waiting_ListArgs = {
  details: AddressToWaitingList;
};

export type NetworkService = {
  __typename?: "NetworkService";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Offering = {
  __typename?: "Offering";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  is_addional?: Maybe<Scalars["Boolean"]>;
  is_sim_only?: Maybe<Scalars["Boolean"]>;
  use_price_categories?: Maybe<Scalars["Boolean"]>;
  offering_contract_periods?: Maybe<Array<Maybe<OfferingContractPeriod>>>;
  offering_sim_cards?: Maybe<Array<Maybe<OfferingSimCard>>>;
  offering_subscription_types?: Maybe<Array<Maybe<OfferingSubscriptionType>>>;
  offering_fttb_subscription_types?: Maybe<Array<Maybe<OfferingSubscriptionType>>>;
};

export type OfferingContractPeriod = {
  __typename?: "OfferingContractPeriod";
  contract_type?: Maybe<Scalars["String"]>;
  early_termination_fees?: Maybe<Scalars["Decimal"]>;
  length?: Maybe<Scalars["Int"]>;
};

export type OfferingSimCard = {
  __typename?: "OfferingSimCard";
  price?: Maybe<Scalars["Decimal"]>;
  catalog_sim_card?: Maybe<CatalogSimCard>;
};

export type OfferingSubscriptionType = {
  __typename?: "OfferingSubscriptionType";
  id?: Maybe<Scalars["ID"]>;
  subscription_types?: Maybe<Array<Maybe<SubscriptionType>>>;
  line_type?: Maybe<LineTypeEnum>;
};

export type OptionGroup = {
  __typename?: "OptionGroup";
  name?: Maybe<Language>;
  packages?: Maybe<Array<Maybe<Package>>>;
};

export type OrderAddressInput = {
  co_address?: Maybe<Scalars["String"]>;
  street: Scalars["String"];
  house_number?: Maybe<Scalars["String"]>;
  building?: Maybe<Scalars["String"]>;
  apartment?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  street_type?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  additional_emails?: Maybe<Scalars["String"]>;
  contact_phone?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
};

export type OrderCustomerInput = {
  first_name: Scalars["String"];
  middle_name?: Maybe<Scalars["String"]>;
  last_name: Scalars["String"];
  first_name_alt?: Maybe<Scalars["String"]>;
  last_name_alt?: Maybe<Scalars["String"]>;
  id_number: Scalars["String"];
  id_type: IdTypeEnum;
  password?: Maybe<Scalars["String"]>;
  individual_tax_number?: Maybe<Scalars["String"]>;
  id_doc_issue_date?: Maybe<Scalars["Date"]>;
  id_doc_valid_till_date?: Maybe<Scalars["Date"]>;
  id_doc_issued_by?: Maybe<Scalars["String"]>;
  language: Scalars["String"];
  birthday?: Maybe<Scalars["Date"]>;
  is_company: Scalars["Boolean"];
  vat_usage_code?: Maybe<Scalars["String"]>;
  sales_tax_usage_code?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  /** define customer is anonymous or not for PrP subscription */
  is_anonymous_customer?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  invoice_delivery_method?: Maybe<Scalars["String"]>;
  call_itemization?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  occupation?: Maybe<Scalars["String"]>;
  trade_register_id?: Maybe<Scalars["String"]>;
  marketing_own?: Maybe<Scalars["Boolean"]>;
  existing_customer_id?: Maybe<Scalars["Int"]>;
  payment_method?: Maybe<Scalars["String"]>;
  apsid?: Maybe<Scalars["Int"]>;
  society_type?: Maybe<Scalars["String"]>;
  taxid?: Maybe<Scalars["String"]>;
  address: OrderAddressInput;
  without_documents?: Maybe<Scalars["Boolean"]>;
};

export type OrdererAccountLegalAddress = {
  /** ID of the contact-media entity */
  id?: Maybe<Scalars["String"]>;
  /** Always 'contact-media' */
  type?: Maybe<Scalars["String"]>;
  attributes?: Maybe<OrdererAccountLegalAddressAttributes>;
};

export type OrdererAccountLegalAddressAttributes = {
  /** The role of the contact information in relation to the party it is linked to. */
  role?: Maybe<AccountLegalAddressAttributesEnum>;
  /**
   * Type of the medium in text format. Its value can be used to validate the format and
   * value of the medium object.
   */
  medium_type?: Maybe<Scalars["String"]>;
  meta?: Maybe<OrdererAccountLegalAddressAttributesMeta>;
  street?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type OrdererAccountLegalAddressAttributesMeta = {
  type?: Maybe<Scalars["String"]>;
};

export type OrderFmcInput = {
  brand?: Maybe<Scalars["Int"]>;
  delivery_type: DeliveryTypeEnum;
  sales_info: SalesInfoInput;
  products: OrderFmcProductInput;
  desired_connection_date?: Maybe<Scalars["Datetime"]>;
};

export type OrderFmcProductInput = {
  subscription?: Maybe<SubscriptionFmcInput>;
};

export type OrderInput = {
  offer?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["Int"]>;
  delivery_type: DeliveryTypeEnum;
  correction_for?: Maybe<Scalars["String"]>;
  confirmation_code?: Maybe<Scalars["String"]>;
  by_pass_telemarketing?: Maybe<Scalars["Boolean"]>;
  customer: OrderCustomerInput;
  sales_info: SalesInfoInput;
  products: OrderProductInput;
  user?: Maybe<OrderCustomerInput>;
  billing_account?: Maybe<BillingAccountInput>;
  desired_connection_date?: Maybe<Scalars["Datetime"]>;
  complex_sale?: Maybe<Scalars["Boolean"]>;
  need_starterkit?: Maybe<Scalars["Boolean"]>;
};

export type OrderProductInput = {
  subscription: SubscriptionInput;
  sim?: Maybe<SimInput>;
};

export enum OrderTypeEnum {
  Acquisition = "acquisition",
  Retention = "retention",
  Personalization = "personalization",
  SimpleSales = "simple_sales"
}

export type OtpSubscription = {
  __typename?: "OTPSubscription";
  /** OTP expiry time */
  expires_in?: Maybe<Scalars["Int"]>;
  /** Related subscription */
  subscription?: Maybe<Subscription>;
};

export type Package = {
  __typename?: "Package";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  name?: Maybe<Language>;
  allow_re_activation?: Maybe<Scalars["String"]>;
  periodic_amount?: Maybe<Scalars["Int"]>;
  periodic_unit?: Maybe<Scalars["String"]>;
  charge_type?: Maybe<Scalars["String"]>;
  fee?: Maybe<Scalars["ID"]>;
  fee_type?: Maybe<Scalars["String"]>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  short_description?: Maybe<Language>;
  medium_description?: Maybe<Language>;
  long_description?: Maybe<Language>;
  product_instance_id?: Maybe<Scalars["Int"]>;
  is_mandatory?: Maybe<Scalars["Boolean"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  provisioning_parameters?: Maybe<Array<Maybe<PackageProvisioningParameter>>>;
  service_parameters?: Maybe<Scalars["String"]>;
};

export type PackageGroup = {
  __typename?: "PackageGroup";
  code?: Maybe<Scalars["String"]>;
  commercial_name?: Maybe<Language>;
  balances?: Maybe<Array<Maybe<Balance>>>;
};

export type PackageInput = {
  name: Scalars["String"];
  services?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PackageProvisioningParameter = {
  __typename?: "PackageProvisioningParameter";
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  is_provisionable?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  raw_value?: Maybe<Scalars["String"]>;
  value_type?: Maybe<Scalars["String"]>;
};

export enum PassportizedEnum {
  Passportized = "passportized",
  NotPassportized = "not_passportized"
}

export type Payment = {
  __typename?: "Payment";
  payment_id?: Maybe<Scalars["ID"]>;
  invoice_external_id?: Maybe<Scalars["String"]>;
  payment_archive_id?: Maybe<Scalars["String"]>;
  payment_amount?: Maybe<Scalars["Float"]>;
  payment_type?: Maybe<Scalars["String"]>;
  payment_date?: Maybe<Scalars["String"]>;
};

export type PortinDueDates = {
  __typename?: "PortinDueDates";
  id?: Maybe<Scalars["String"]>;
  due_date?: Maybe<Scalars["Date"]>;
  time_start?: Maybe<Scalars["Time"]>;
  time_end?: Maybe<Scalars["Time"]>;
};

export type Preorder = {
  /** subscription_id or fttb_subscription_msisdn must be supplied */
  subscription_id?: Maybe<Scalars["ID"]>;
  /** subscription_id or fttb_subscription_msisdn must be supplied */
  fttb_subscription_msisdn?: Maybe<Scalars["String"]>;
  mobile_subscription_id: Scalars["ID"];
  subscription_type_id: Scalars["ID"];
  validity_of_preorder: Scalars["Date"];
  state: Scalars["Int"];
  packages?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type ProductFamily = {
  __typename?: "ProductFamily";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Language>;
  max_choices?: Maybe<Scalars["Int"]>;
  packages?: Maybe<Array<Maybe<Package>>>;
};

export enum ProductTypeEnum {
  Subscription = "Subscription",
  Collateral = "Collateral",
  Simcard = "Simcard",
  Article = "Article",
  Device = "Device"
}

export type Query = {
  __typename?: "Query";
  user_permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  send_password?: Maybe<Response>;
  user_identity?: Maybe<Jwt>;
  billing_account?: Maybe<BillingAccount>;
  company_billing_accounts?: Maybe<Array<Maybe<BillingAccount>>>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  companys?: Maybe<Array<Maybe<Customer>>>;
  validate_icc?: Maybe<ValidateIcc>;
  livesetting?: Maybe<LiveSettingResponse>;
  msisdns?: Maybe<Array<Maybe<Msisdn>>>;
  msisdn?: Maybe<Msisdn>;
  available_numbers?: Maybe<Array<Maybe<Msisdn>>>;
  offerings?: Maybe<Array<Maybe<Offering>>>;
  offerings_under_city?: Maybe<Array<Maybe<OfferingSubscriptionType>>>;
  subscription_type_offering?: Maybe<Offering>;
  limited_subscription?: Maybe<LimitedSubscription>;
  /** Query subscriptions with defined filters. Supports pagination. */
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  subscription_types?: Maybe<Array<Maybe<SubscriptionType>>>;
  /** SubscriptionType query to fetch information from PC */
  subscription_type?: Maybe<SubscriptionType>;
  associated_subscription_type?: Maybe<SubscriptionType>;
  optional_products?: Maybe<Array<Maybe<ProductFamily>>>;
  subscription_types_by_type_category?: Maybe<Array<Maybe<SubscriptionType>>>;
  cas_validate_otp?: Maybe<CasResult>;
  customer_identification?: Maybe<CustomerIdentification>;
  gis_countries?: Maybe<Array<Maybe<GisCountry>>>;
  gis_regions?: Maybe<Array<Maybe<GisRegion>>>;
  gis_cities?: Maybe<Array<Maybe<GisCity>>>;
  gis_districts?: Maybe<Array<Maybe<GisDistrict>>>;
  gis_streets?: Maybe<Array<Maybe<GisStreet>>>;
  gis_houses?: Maybe<GisHouse>;
  gis_house_previous_activation_status?: Maybe<GisHousePreviousActivationStatus>;
  gis_flats?: Maybe<Array<Maybe<GisFlat>>>;
  /** Cramer check using fttb-gigabit-status */
  fttb_gigabit_status?: Maybe<FttbGigabitStatus>;
  child_offers?: Maybe<Array<Maybe<ChildOffer>>>;
  portin_duedates?: Maybe<Array<Maybe<PortinDueDates>>>;
  /** Subscription details. OTP must be requested beforehand and provided to query. */
  otp_subscription?: Maybe<OtpSubscription>;
  region_optional_products?: Maybe<Array<Maybe<ProductFamily>>>;
};


export type QuerySend_PasswordArgs = {
  username: Scalars["String"];
};


export type QueryUser_IdentityArgs = {
  get_permissions?: Maybe<Scalars["Boolean"]>;
};


export type QueryBilling_AccountArgs = {
  billing_account_id: Scalars["ID"];
};


export type QueryCustomersArgs = {
  id_number?: Maybe<Scalars["String"]>;
  id_type?: Maybe<Scalars["String"]>;
  individual_tax_number?: Maybe<Scalars["ID"]>;
};


export type QueryCompanysArgs = {
  first_name?: Maybe<Scalars["String"]>;
  business_uid?: Maybe<Scalars["String"]>;
};

export type QueryBillingAccountsArgs = {
  company_id: Scalars["ID"];
  page_size?: Maybe<Scalars["Int"]>;
  page_number?: Maybe<Scalars["Int"]>;
};

export type QueryValidate_IccArgs = {
  icc: Scalars["ID"];
  offer_id: Scalars["ID"];
};


export type QueryLivesettingArgs = {
  name: LiveSettingEnum;
};


export type QueryMsisdnsArgs = {
  mask?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["Int"]>;
  stock?: Maybe<Scalars["Int"]>;
  page_no?: Maybe<Scalars["Int"]>;
  page_size?: Maybe<Scalars["Int"]>;
};


export type QueryMsisdnArgs = {
  msisdn: Scalars["String"];
  is_b2b_fmc?: Maybe<Scalars["Boolean"]>;
};


export type QueryAvailable_NumbersArgs = {
  mask?: Maybe<Scalars["String"]>;
  stock?: Maybe<Scalars["Int"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_no?: Maybe<Scalars["Int"]>;
};


export type QueryOfferings_Under_CityArgs = {
  gis_city_id: Scalars["ID"];
};


export type QuerySubscription_Type_OfferingArgs = {
  subscription_type_id: Scalars["ID"];
};


export type QueryLimited_SubscriptionArgs = {
  msisdn: Scalars["String"];
};


export type QuerySubscriptionsArgs = {
  contact_phone: Scalars["String"];
  page?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
};


export type QuerySubscription_TypesArgs = {
  order_type: Scalars["String"];
};


export type QuerySubscription_TypeArgs = {
  subscription_type_id: Scalars["ID"];
};


export type QueryAssociated_Subscription_TypeArgs = {
  subscription_type_id: Scalars["ID"];
};


export type QueryOptional_ProductsArgs = {
  subscription_type_id: Scalars["ID"];
};


export type QuerySubscription_Types_By_Type_CategoryArgs = {
  subscription_type_category?: Maybe<Scalars["String"]>;
};


export type QueryCas_Validate_OtpArgs = {
  msisdn: Scalars["String"];
  otp: Scalars["String"];
};


export type QueryCustomer_IdentificationArgs = {
  msisdn: Scalars["String"];
  since?: Maybe<Scalars["String"]>;
};


export type QueryGis_CountriesArgs = {
  language: LanguageEnum;
  country_id?: Maybe<Scalars["ID"]>;
};


export type QueryGis_RegionsArgs = {
  language: LanguageEnum;
  country_id?: Maybe<Scalars["ID"]>;
  region_name?: Maybe<Scalars["String"]>;
};


export type QueryGis_CitiesArgs = {
  language: LanguageEnum;
  country_id?: Maybe<Scalars["ID"]>;
  city_name?: Maybe<Scalars["String"]>;
  district_id?: Maybe<Scalars["ID"]>;
  region_id?: Maybe<Scalars["ID"]>;
};


export type QueryGis_DistrictsArgs = {
  language: LanguageEnum;
  region_id: Scalars["ID"];
};


export type QueryGis_StreetsArgs = {
  language: LanguageEnum;
  city_id?: Maybe<Scalars["ID"]>;
  street_name?: Maybe<Scalars["String"]>;
};


export type QueryGis_HousesArgs = {
  language: LanguageEnum;
  house_id: Scalars["ID"];
};


export type QueryGis_House_Previous_Activation_StatusArgs = {
  house_id: Scalars["ID"];
  apartment?: Maybe<Scalars["String"]>;
};


export type QueryGis_FlatsArgs = {
  flat_id?: Maybe<Scalars["ID"]>;
  house_id?: Maybe<Scalars["String"]>;
  flat_number?: Maybe<Scalars["String"]>;
  region_id?: Maybe<Scalars["String"]>;
  street_id?: Maybe<Scalars["String"]>;
  house_number?: Maybe<Scalars["String"]>;
  page_number?: Maybe<Scalars["Int"]>;
  page_size?: Maybe<Scalars["Int"]>;
};

export type QueryFttb_Gigabit_StatusArgs = {
  msisdn: Scalars["String"];
};


export type QueryChild_OffersArgs = {
  subscription_type_id: Scalars["ID"];
  parent_product: Scalars["Int"];
  relation_type?: Maybe<Scalars["String"]>;
};


export type QueryOtp_SubscriptionArgs = {
  msisdn: Scalars["String"];
  otp: Scalars["String"];
  is_b2b_fmc?: Maybe<Scalars["Boolean"]>;
};


export type QueryRegion_Optional_ProductsArgs = {
  subscription_type_id: Scalars["ID"];
  gis_house_id?: Maybe<Scalars["String"]>;
  gis_flat_id?: Maybe<Scalars["String"]>;
};

export type RatePlan = {
  /** fttb plan type like prepaid or postpaid */
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type RecordBalance = {
  __typename?: "RecordBalance";
  transaction_amount?: Maybe<Scalars["Float"]>;
  balance_before?: Maybe<Scalars["Float"]>;
  balance_after?: Maybe<Scalars["Float"]>;
  unit_type?: Maybe<Scalars["Int"]>;
  balance_id?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  offer_id?: Maybe<Scalars["Int"]>;
};

export type Response = {
  __typename?: "Response";
  request_id?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  error?: Maybe<Scalars["String"]>;
  error_code?: Maybe<Scalars["Int"]>;
  attributes?: Maybe<Attributes>;
};

export type SalesInfoInput = {
  sales_type: SalesTypeEnum;
  dealer_language?: Maybe<LanguageEnum>;
  reseller_code?: Maybe<Scalars["String"]>;
};

export enum SalesTypeEnum {
  Acquisition = "acquisition",
  Retention = "retention",
  Personalization = "personalization",
  SimpleSales = "simple_sales",
  MnpAcquisition = "mnp_acquisition",
  B2BFttbAcquisition = "B2B_fttb_acquisition",
  FttbAcquisition = "fttb_acquisition",
  /** Used for GSM + FTTB merger to FMC subscription. */
  FmcMerge = "fmc_merge",
  B2cPostpaidFmc = "b2c_postpaid_fmc",
  B2bPostpaidFmc = "b2b_postpaid_fmc"
}

export enum SalutationEnum {
  Mr = "MR",
  Ms = "MS",
  Madame = "MADAME",
  Monsieur = "MONSIEUR",
  Frau = "FRAU",
  Herr = "HERR",
  Signora = "SIGNORA",
  Signor = "SIGNOR"
}

export type Shop = {
  __typename?: "Shop";
  code?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
  thana?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  store?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  person1?: Maybe<Scalars["String"]>;
  person2?: Maybe<Scalars["String"]>;
  hours?: Maybe<Scalars["String"]>;
  holiday?: Maybe<Scalars["String"]>;
  halfday?: Maybe<Scalars["String"]>;
};

export enum SimAvailableActionsEnum {
  Replace = "REPLACE",
  Swap = "SWAP"
}

export type SimCard = {
  __typename?: "SimCard";
  icc?: Maybe<Scalars["String"]>;
  sim_type?: Maybe<Scalars["String"]>;
};

export type SimInput = {
  product_type: ProductTypeEnum;
  type: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  icc: Scalars["String"];
  msisdn: MsisdnInput;
  reservation_id: Scalars["String"];
};

export enum SimTypeEnum {
  Normal = "normal",
  Micro = "micro",
  Nano = "nano",
  Trio = "trio",
  NfcTrio = "nfc_trio",
  Usim = "USIM",
  Sim = "SIM"
}

export type Subscription = {
  __typename?: "Subscription";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<Scalars["String"]>;
  msisdn?: Maybe<Scalars["String"]>;
  msisdn_details?: Maybe<Msisdn>;
  status?: Maybe<Scalars["String"]>;
  payment_type?: Maybe<Scalars["String"]>;
  sim_card?: Maybe<SimCard>;
  subscription_type?: Maybe<SubscriptionType>;
  available_subscription_types?: Maybe<Array<Maybe<SubscriptionType>>>;
  owner?: Maybe<Customer>;
  payer?: Maybe<Customer>;
  user?: Maybe<Customer>;
  active_packages?: Maybe<Array<Maybe<Package>>>;
  available_packages?: Maybe<Array<Maybe<Package>>>;
  available_families?: Maybe<Array<Maybe<ProductFamily>>>;
  usage?: Maybe<Usage>;
  balances?: Maybe<Usage>;
  billing?: Maybe<BillingReport>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  barrings?: Maybe<Array<Maybe<Barring>>>;
  services?: Maybe<Array<Maybe<NetworkService>>>;
  request?: Maybe<Memo>;
  campaign_offers?: Maybe<Array<Maybe<CampaignOffer>>>;
  activation_time?: Maybe<Scalars["String"]>;
  first_call_date?: Maybe<Scalars["String"]>;
  all_balances?: Maybe<Array<Maybe<Balance>>>;
  /** Any ongoing or scheduled changes (Memos) a subscription has. */
  ongoing_changes?: Maybe<Array<Maybe<Memo>>>;
  /** All billing accounts tied to this subscription. */
  billing_accounts?: Maybe<Array<Maybe<BillingAccount>>>;
  fmc_merge_status?: Maybe<Scalars["String"]>;
  is_portout_ongoing?: Maybe<Scalars["Boolean"]>;
  is_tv_tuner_debt?: Maybe<Scalars["Boolean"]>;
  /** Subscription resource to expose if used as a temporary msisdn */
  is_temporary_msisdn?: Maybe<Scalars["Boolean"]>;
  compatible_products?: Maybe<CompatibleProducts>;
  is_fttb_current_payment_status?: Maybe<Scalars["Boolean"]>;
  is_fttb_first_payment_status?: Maybe<Scalars["Boolean"]>;
};


export type SubscriptionAvailable_Subscription_TypesArgs = {
  agent_id?: Maybe<Scalars["Int"]>;
  line_type?: Maybe<Scalars["String"]>;
  city_id?: Maybe<Scalars["String"]>;
  payment_type: Scalars["String"];
};


export type SubscriptionAvailable_PackagesArgs = {
  tag?: Maybe<Scalars["String"]>;
};


export type SubscriptionAvailable_FamiliesArgs = {
  family?: Maybe<Scalars["String"]>;
};


export type SubscriptionBillingArgs = {
  account: Scalars["String"];
};


export type SubscriptionInvoicesArgs = {
  account: Scalars["String"];
};


export type SubscriptionPaymentsArgs = {
  account: Scalars["String"];
};


export type SubscriptionBarringsArgs = {
  status: Scalars["String"];
};


export type SubscriptionServicesArgs = {
  status: Scalars["String"];
};


export type SubscriptionRequestArgs = {
  id: Scalars["String"];
};


export type SubscriptionCompatible_ProductsArgs = {
  sub_type_code: Scalars["String"];
};

export type SubscriptionFmcInput = {
  /** FMC SubscriptionType */
  type: Scalars["String"];
  /** Mobile subscription id */
  mobile_subscription_id: Scalars["String"];
  /** FTTB subscription id */
  fttb_subscription_id: Scalars["String"];
  barrings?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** List of package code/s */
  packages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  deactivate_packages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Product type to be Subscription */
  product_type: ProductTypeEnum;
};

export type SubscriptionInput = {
  barrings?: Maybe<Array<Maybe<Scalars["String"]>>>;
  connection_type?: Maybe<ConnectionTypeEnum>;
  termination_penalty_fee?: Maybe<Scalars["Float"]>;
  payer?: Maybe<OrderCustomerInput>;
  initial_period?: Maybe<Scalars["Int"]>;
  user_privacy?: Maybe<Scalars["String"]>;
  msisdn: Scalars["String"];
  paying_monthly?: Maybe<Scalars["Boolean"]>;
  recurring_period?: Maybe<Scalars["Int"]>;
  retention_penalty_fee?: Maybe<Scalars["Float"]>;
  type: Scalars["String"];
  product_type: ProductTypeEnum;
  user?: Maybe<OrderCustomerInput>;
  packages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  deactivate_packages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  fttb_connection_type?: Maybe<FttbConnectionTypeEnum>;
  /** Value to be used incase of MNP order.  */
  mnp?: Maybe<MnpOrderInput>;
  comment?: Maybe<Scalars["String"]>;
  reservation_id?: Maybe<Scalars["String"]>;
};

export type SubscriptionType = {
  __typename?: "SubscriptionType";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Language>;
  payment_type?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  change_price?: Maybe<Scalars["Float"]>;
  change_price_currency?: Maybe<Scalars["String"]>;
  info_text?: Maybe<Language>;
  long_info_text?: Maybe<Language>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  option_groups?: Maybe<Array<Maybe<OptionGroup>>>;
  line_type?: Maybe<LineTypeEnum>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  subscription_type_category?: Maybe<Scalars["String"]>;
  billing_account_type?: Maybe<BillingAccountType>;
};

export enum SubscriptionTypeCategoryEnum {
  Normal = "normal",
  Convergent = "convergent",
  Fmcpopmerge = "fmcpopmerge",
  B2BFTTB = "b2bfttb",
  FMCB2BPopMerge = "FMCB2BPOPMERGE"
}


export type Token = {
  __typename?: "Token";
  token?: Maybe<Scalars["String"]>;
  refresh_token?: Maybe<Scalars["String"]>;
  expires_in?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type TokenInput = {
  token?: Maybe<Scalars["String"]>;
  refresh_token?: Maybe<Scalars["String"]>;
  expires_in?: Maybe<Scalars["String"]>;
};


export type UploadAcquisitionDocument = {
  confirmation_code?: Maybe<Scalars["ID"]>;
  files: Array<Scalars["Upload"]>;
  document_type: Scalars["String"];
  purpose?: Maybe<Scalars["String"]>;
  customer_type?: Maybe<Scalars["String"]>;
  document_uid: Scalars["String"];
};

export type UploadDocument = {
  subscription_id: Scalars["ID"];
  files: Array<Scalars["Upload"]>;
  document_type: Scalars["String"];
  purpose?: Maybe<Scalars["String"]>;
  document_uid: Scalars["String"];
};

export type Usage = {
  __typename?: "Usage";
  main_balance?: Maybe<Scalars["Float"]>;
  currency?: Maybe<Scalars["String"]>;
  packages?: Maybe<Array<Maybe<PackageGroup>>>;
};

export type UserDealerIdentity = {
  __typename?: "UserDealerIdentity";
  contact_number?: Maybe<Scalars["String"]>;
  reseller_code?: Maybe<Scalars["String"]>;
  reseller_name?: Maybe<Scalars["String"]>;
  chain_name?: Maybe<Scalars["String"]>;
};

export type UserPermissionGroup = {
  __typename?: "UserPermissionGroup";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ValidateIcc = {
  __typename?: "ValidateIcc";
  status?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type Validfor = {
  meta: ValidforMetaInput;
  /** example: 2020-10-10T12:00:00Z */
  start_datetime?: Maybe<Scalars["String"]>;
  /**
   * Open ended validity period is represented with an empty date-time.
   * example: 2020-10-10T12:00:00Z
   */
  end_datetime?: Maybe<Scalars["String"]>;
};

export type ValidforMetaInput = {
  type: Scalars["String"];
};

export enum DonorSegmentEnum {
  b2cPassportized = "prp_b2c_passportized",
  b2cNonPassportized = "prp_non_passportized",
  mmc = "pop_mmc_first_last",
  popEntrepreneur = "pop_entrepreneur",
  popB2b = "pop_b2b"
}
