import { useFormik } from "formik";
import React, { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonMessages } from "../../Common.messages";
import { config } from "../../config/constants";
import { Query, QueryCustomer_IdentificationArgs, QueryOtp_SubscriptionArgs } from "../../graphql/types";
import { FMCMessages } from "../../modules/fmc/FMC.messages";
import { UserMessages } from "../../modules/user/User.messages";
import { InputFieldFormik } from "./InputField";
import { errorActions, useStoreContext } from "./navigation/StepStateProvider";
import { useOtp } from "./Otp";
import { MsisdnErrorsUtils } from "../utils/MsisdnErrorsUtils.utils";
import { GET_CUSTOMER_IDENTIFICATION } from "../../graphql/queries"
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { SUBSCRIPTION_FMC_POSTPAID_QUERY } from "../../graphql/queries/subscription";
import { useAuthDataContext } from "../hooks/AuthentificationProvider";
import { addPrefixToMsisdn, hasRole } from "../utils/utils";
import { PermissionRolesEnum } from "../types/PermissionRolesEnum";

interface SearchMsisdnProps{
	handleResetSearch: () => void;
	disabled?: boolean;
	onCompleted: (msisdn: string, otp: string) => Promise<string|void|Required<Pick<Query, "otp_subscription">>>;
	isB2bFmc?: boolean | undefined;
	isCoordinatorSelected?: boolean | undefined;
	isSubscriberSelected?: boolean | undefined;
}
const SearchMsisdn: FC<SearchMsisdnProps> = (props) => {
	const {userPermissions} = useAuthDataContext();
	const { handleResetSearch, onCompleted, isB2bFmc, isCoordinatorSelected, isSubscriberSelected} = props;
	const intl = useIntl();
	const {dispatch} = useStoreContext();
	let canSKipCustomerIdentification = false;

	if (isB2bFmc) {
		canSKipCustomerIdentification = hasRole(userPermissions, [PermissionRolesEnum.CAN_SKIP_CUSTOMER_IDENTIFICATION])
	}

	const [getCustomerIdentification, { loading: loadingCustomerIdentification }] = useLazyQuery<Required<Pick<Query, "customer_identification">>, QueryCustomer_IdentificationArgs>(GET_CUSTOMER_IDENTIFICATION, {
		onCompleted: (data) => {
			const msisdn = formik.values?.msisdn;
			const status = data?.customer_identification?.status;
			if (!status || status === "404" || status === "false") {
				MsisdnErrorsUtils.validate(null, "customerNotIdentified")
					.next(msisdn, "customerNotIdentified")
					.dispatch(msisdn, dispatch);
				return;
			}
			dispatch(errorActions.cleanError());
			onCompleted(addPrefixToMsisdn(msisdn), " ");
		}
	});

	const [getSubscription, { loading: loadingPersonalService }] = useLazyQuery<Required<Pick<Query, "otp_subscription">>, QueryOtp_SubscriptionArgs>(SUBSCRIPTION_FMC_POSTPAID_QUERY, {
		onCompleted: (data) => {
			const isPersonalServicePackageActive = data?.otp_subscription?.subscription?.active_packages?.some((pkg) => pkg?.code === "INDSERMANAGE" );
			const msisdn = formik.values?.msisdn;

			if(!data) {
				MsisdnErrorsUtils.validate(null, "fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge")
					.next(msisdn, "fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge")
					.dispatch(msisdn, dispatch);
				return;
			}

			const subscription = data?.otp_subscription?.subscription;
			const lineType = subscription?.subscription_type?.line_type;

			if (subscription && lineType && ["mobile", "fmc"].includes(lineType)) {
				// GSM subscription
				if (!canSKipCustomerIdentification) {
					if (isCoordinatorSelected) {
						getCustomerIdentification({
							variables: {
								msisdn: addPrefixToMsisdn(msisdn)
							}
						});
					} else if(isSubscriberSelected) {
						if (!isPersonalServicePackageActive) {
							MsisdnErrorsUtils.validate(null, "personalServicePkgNotActive")
							.next(msisdn, "personalServicePkgNotActive")
							.dispatch(msisdn, dispatch);
						} else {
							dispatch(errorActions.cleanError());
							handleOtp(msisdn); // OTP validation for GSM
						}
					}
				} else {
					// skip OTP validation
					onCompleted(addPrefixToMsisdn(msisdn), " ");
				}
			}

			if (subscription && lineType === "fttb") {
				dispatch(errorActions.cleanError());
				handleOtp(msisdn); // OTP validation for FTTB
			}

		}
	});

	const formik = useFormik({
		initialValues: { msisdn: ""},
		onSubmit: (values) => {
			const { msisdn } = values;
			if (isB2bFmc) {
				getSubscription({
					variables: {
						msisdn: addPrefixToMsisdn(msisdn),
						otp: " ",
						is_b2b_fmc: true
					}
				});
			} else {
				dispatch(errorActions.cleanError());
				handleOtp(msisdn);
			}
		},
		//validationSchema: searchSubscription(intl)
	});
	const {handleOtp, loading} = useOtp(formik.values.msisdn, onCompleted);
	const label = useMemo(() => intl.formatMessage({...CommonMessages.searchSubscription}), [intl.locale]);

	const resetForm = (e) => {
		dispatch(errorActions.cleanError());
		dispatch(errorActions.cleanMsisdnErrors());
		handleResetSearch();
		formik.handleReset(e);
	};
	return (
		<form onSubmit={formik.handleSubmit} className="form-row bg-light p-4 mb-5">
			<div className="form-group col-lg-4 col-12">
				<InputFieldFormik
					disabled={props.disabled}
					required
					name="msisdn"
					id="msisdn"
					attr={{maxLength: config.PHONE_LENGTH}}
					placeholder={label}
					value={formik.values.msisdn}
					formik={formik}
				/>
			</div>
			<div className="form-group col-lg-4 col-12">
				<button type="submit" disabled={(loading || loadingCustomerIdentification || loadingPersonalService) || props.disabled} className="btn btn-block btn-primary">
					{(loading || loadingCustomerIdentification || loadingPersonalService) ? <i className="w-spinner"></i> : <i className="fa fa-search"></i>} &nbsp;
					<FormattedMessage {...UserMessages.search} />
				</button>
			</div>
			<div className="form-group col-lg-4 col-12">
				<button type="button" disabled={loading} onClick={resetForm} className="btn btn-block btn-primary">
					<FormattedMessage {...FMCMessages.resetForm} />
				</button>
			</div>
		</form>
	);
};

export {
	SearchMsisdn
};