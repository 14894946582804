import * as React from "react";
import { Switch } from "react-router";
import { ErrorBoundaryRoute } from "../shared/error/ErrorBoundaryRoute";
import PageNotFound from "../shared/error/PageNotFound";
import { AcquisitionPageRouter } from "../modules/acquisitionFlow/NewCustomer/Pages";
import { FttbPageRouter } from "../modules/fttb/Routes/FttbPageRouter";
import { AcquisitionExistingPageRouter } from "../modules/acquisitionFlow/ExistingCustomer";
import { iposRoutes } from "./RoutesMap";
import { Login } from "../modules/login/Login";
import { PrivateRouter } from "../shared/components/PrivateRouter";
import { withSearch } from "../modules/search/withSearch";
import { Logout } from "../modules/login/Logout";
import { FTTB_FLOW_PATH } from "../modules/fttb/Routes/FttbFlowRoutes";
import { FMC_FLOW_PATH } from "../modules/fmc/Routes/FMCPageRoutes";
import { FmcPageRouter } from "../modules/fmc/Routes/FmcPageRouter";
import { MNPPageRouter } from "../modules/mnp/MNPPageRouter";
import { Main } from "../modules/main/Main";
import { FmcPostpaidPageRouter, FMC_POSTPAID_FLOW_PATH, FMC_POSTPAID_B2B_FLOW } from "../modules/fmc/postpaid";
import { StepStateProvider } from "../shared/components/navigation/StepStateProvider";
import {B2B_FTTB_FLOW_PATH} from "../modules/fttb/b2bFttb/Routes/B2BFttbFlowRoutes";
import {B2BFttbPageRouter} from "../modules/fttb/b2bFttb/Routes/B2BFttbPageRouter";
import {PostpaidFttbPageRouter} from "../modules/mnp/postpaidMnp/MNPPageRouter";
import {MNP_FLOW_POSTPAID} from "../modules/mnp/postpaidMnp/MNPFlowRoutes";

const INDEX_PATH = iposRoutes.INDEX.createLink();
const LOGIN_PATH = iposRoutes.LOGIN.createLink();
const LOGOUT_PATH = iposRoutes.LOGOUT.createLink();
const ACQUISITION_FLOW_PATH = iposRoutes.ACQUISITION_FLOW.createLink();
const MNP_FLOW_PATH = iposRoutes.MNP_FLOW.createLink();
const ACQUISITION_FLOW_EXISTING_PATH = iposRoutes.ACQUISITION_EXISTING_FLOW.createLink();

const AppBodyRoutes = () => (
	<StepStateProvider>
		<Switch>
			<ErrorBoundaryRoute exact path={LOGIN_PATH} component={Login}/>
			<ErrorBoundaryRoute exact path={LOGOUT_PATH} component={Logout}/>
			<PrivateRouter exact path={INDEX_PATH} component={withSearch(Main)}/>
			<PrivateRouter path={ACQUISITION_FLOW_PATH} component={withSearch(AcquisitionPageRouter)}/>
			<PrivateRouter path={ACQUISITION_FLOW_EXISTING_PATH} component={withSearch(AcquisitionExistingPageRouter)}/>
			<PrivateRouter path={MNP_FLOW_PATH} component={withSearch(MNPPageRouter)}/>
			<PrivateRouter path={MNP_FLOW_POSTPAID} component={withSearch(PostpaidFttbPageRouter)}/>
			<PrivateRouter path={FTTB_FLOW_PATH} component={withSearch(FttbPageRouter)}/>
			<PrivateRouter path={B2B_FTTB_FLOW_PATH} component={withSearch(B2BFttbPageRouter)}/>
			<PrivateRouter path={FMC_FLOW_PATH} component={withSearch(FmcPageRouter)}/>
			<PrivateRouter path={FMC_POSTPAID_FLOW_PATH} component={withSearch(FmcPostpaidPageRouter)}/>
			<PrivateRouter path={FMC_POSTPAID_B2B_FLOW} component={withSearch(FmcPostpaidPageRouter)}/>
			<PrivateRouter component={withSearch(PageNotFound)}/>
		</Switch>
	</StepStateProvider>
);

export { AppBodyRoutes, LOGIN_PATH };
