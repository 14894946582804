/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface FMCMessagesType {
	FMC: MessageDescriptor;
	address: MessageDescriptor;
	coordinatorLabel: MessageDescriptor;
	errorOption82: MessageDescriptor;
	fmcConnection: MessageDescriptor;
	fttOrder: MessageDescriptor;
	fttbOptions: MessageDescriptor;
	fttbPlans: MessageDescriptor;
	fttbSubscription: MessageDescriptor;
	fttbSubscriptionIsAlreadyConvertedToFMCError: MessageDescriptor;
	gsmSubscription: MessageDescriptor;
	gponNotAvailable: MessageDescriptor;
	mergeNotPossibleError: MessageDescriptor;
	noFttb: MessageDescriptor;
	options: MessageDescriptor;
	phone: MessageDescriptor;
	plan: MessageDescriptor;
	preOrderCompleted: MessageDescriptor;
	preOrderFailed: MessageDescriptor;
	resetForm: MessageDescriptor;
	search: MessageDescriptor;
	searchNewFttbSubscriptionFailed: MessageDescriptor;
	subscriberLabel: MessageDescriptor;
	successFmcConnection: MessageDescriptor;
	summary: MessageDescriptor;
	unpairFmc: MessageDescriptor;
	unpairSuccess: MessageDescriptor;
	allowOnlyOneSBPackage: MessageDescriptor;
	deactivateGSMSBPackage:MessageDescriptor;
	gsmSBPackage: MessageDescriptor;
	deactivateGSMSBPackageAsFTTBSBPackageSelected;
}
const FMCMessages: FMCMessagesType = defineMessages({
	FMC: {
		id: "fmc-flow-main-title",
		description: "FMC flow label current page",
		defaultMessage: "FMC"
	},
	address: {
		id: "fmc-flow-fttb-address",
		description: "Navigation bar step title FTTB address, without fttb subbscription flow",
		defaultMessage: "FTTB address"
	},
	coordinatorLabel: {
		id: "b2b-fmc-flow-search-coordinator-label",
		description: "B2B FMC coordinator label",
		defaultMessage: "Coordinator"
	},
	errorOption82: {
		id: "fmc-flow-error-option82",
		description: "error option82 missing",
		defaultMessage: "FTTB subscription is missing an active 'OPTION82' package required for FMC merge."
	},
	fmcConnection: {
		id: "fmc-flow-connection",
		description: "Title of FMC connection",
		defaultMessage: "FMC connection"
	},
	fttOrder: {
		id: "fmc-flow-fttbb-order",
		description: "Title of Fttb order summary page",
		defaultMessage: "Fttb order"
	},
	fttbOptions: {
		id: "fmc-flow-fttb-options",
		description: "Navigation bar step title FTTB options, without fttb subbscription flow",
		defaultMessage: "FTTB options"
	},
	fttbPlans: {
		id: "fmc-flow-fttb-plans",
		description: "Navigation bar step title FTTB plans, without fttb subbscription flow",
		defaultMessage: "FTTB plans"
	},
	fttbSubscription: {
		id: "fmc-flow-fttb-subscription",
		description: "Fttb subscription label",
		defaultMessage: "Fttb subscription"
	},
	fttbSubscriptionIsAlreadyConvertedToFMCError: {
		id: "fmc-change-tariff-modal-fttb-subscription-is-already-converted-to-fmc",
		description: "Error message that is displayed to user if tariff changes  is not possible due to is already converted to FMC",
		defaultMessage: "FTTB Subscription is already converted to FMC"
	},
	gsmSubscription: {
		id: "fmc-flow-gsm-subscription",
		description: "Gsm subscription label",
		defaultMessage: "Gsm subscription"
	},
	gponNotAvailable: {
		id: "fmc-flow-gpon-not-available",
		description: "GPON not available message",
		defaultMessage: "GPON services are not available for the selected tariff plan. Services will be disabled."
	},
	mergeNotPossibleError: {
		id: "fmc-service-merge-not-possible-error",
		description: "Merge is not possible, subscription has product in waiting status",
		defaultMessage: "Operation is not possible, you have request in waiting status for service: {name}"
	},
	noFttb: {
		id: "fmc-flow-no-existing-fttb-connection",
		description: "Check Box label FMC search gsm flow",
		defaultMessage: "No existing FTTB connection"
	},
	options: {
		id: "fmc-flow-options-step",
		description: "FMC flow label options page",
		defaultMessage: "Options"
	},
	phone: {
		id: "fmc-flow-connection-phone",
		description: "Label",
		defaultMessage: "Phone"
	},
	plan: {
		id: "fmc-flow-plan-step",
		description: "FMC flow label plan page",
		defaultMessage: "Plan"
	},
	preOrderCompleted: {
		id: "fmc-flow-pre-order-completed",
		description: "FMC pre order completed",
		defaultMessage: "Pre Order completed for fttb: {fttbMsisdn} and fmc id: {fmcId}"
	},
	preOrderFailed: {
		id: "fmc-flow-pre-order-fail",
		description: "FMC pre order error",
		defaultMessage: "Pre Order failed for fttb: {fttbMsisdn} and fmc id: {fmcId}"
	},
	resetForm: {
		id: "fmc-flow-reset-form",
		description: "FMC flow button reset form",
		defaultMessage: "Reset form"
	},
	search: {
		id: "fmc-flow-gsm-search-step",
		description: "FMC flow label search gsm page",
		defaultMessage: "Search GSM"
	},
	searchNewFttbSubscriptionFailed: {
		id: "fmc-flow-search--fttb-fail",
		description: "FMC fttb search failed",
		defaultMessage: "Subscription search failed"
	},
	subscriberLabel: {
		id: "b2b-fmc-flow-search-subscriber-label",
		description: "B2B FMC subscriber label",
		defaultMessage: "Subscriber"
	},
	successFmcConnection: {
		id: "fmc-flow-sucess-connection",
		description: "Success FMC connection order cretion message",
		defaultMessage: "FMC order submitted successfully {gsmMsisdn}"
	},
	summary: {
		id: "fmc-flow-summary-step",
		description: "FMC flow label summary page",
		defaultMessage: "Summary"
	},
	unpairFmc: {
		id: "fmc-flow-unpair-fmc-checkbox-label",
		description: "Check Box unpair fmc postpaid",
		defaultMessage: "FTTB disconnection from FMC"
	},
	unpairSuccess: {
		id: "fmc-flow-unpair-success",
		description: "Unpair success msg",
		defaultMessage: "Process of unpairing Fmc is suceess"
	},
	allowOnlyOneSBPackage: {
		id: "fmc-flow-allow-one-sb-package",
		description: "Allow only one sb package notification description",
		defaultMessage: "Your FTTB subscription also has a swappable benefit package.Deactivate GSM and keep FTTB by checking the box, or vice versa"
	},
	deactivateGSMSBPackage: {
		id: "fmc-flow-deactivate-gsm-sb-package",
		description: "Deactivate gsm SB package",
		defaultMessage: "Deactivate"
	},
	gsmSBPackage: {
		id: "fmc-flow-gsm-sb-package",
		description: "gsm SB package message",
		defaultMessage: "A swappable benefit package is already active on your GSM: "
	},
	deactivateGSMSBPackageAsFTTBSBPackageSelected: {
		id: "fmc-flow-gsm-sb-package-deactivate-as-fttb-SB-selected",
		description: "gsm sb package deactivate as fttb SB selected",
		defaultMessage: "Deactivate the existing SB package before proceeding, as only one SB package is allowed per subscription"
	},
});

export default FMCMessages;
export { FMCMessages };
