import { LineTypeEnum } from "./../types";
import gql from "graphql-tag";
import { SubscriptionClient } from "../fragments/subscription.fragments";
import { TariffPlan } from "../fragments/subscriptionType.fragments";
import { language } from "../fragments/Languages.fragment";
import { Customer } from "../fragments/customer.fragments";

export const SUBSCRIPTION_QUERY = gql`
    query OtpSubscription($msisdn: String!, $otp: String!) {
        otp_subscription(msisdn: $msisdn, otp: $otp) {
            subscription {
                id,
                payment_type,
                is_portout_ongoing,
                is_temporary_msisdn,
                msisdn,
                ...OwnerContactPhone,
                status,
                ...ActivePackages,
                subscription_type {
                    ...tariffPlanDetails
                },
                is_tv_tuner_debt
            }
       }
    },
    ${SubscriptionClient.fragments.activePackages},
    ${TariffPlan.fragments.full},
    ${SubscriptionClient.fragments.ownerContactPhone}
`;

export const GET_BILLING_ACCOUNT_REPORT = gql`query GetBillingAccountReport($msisdn: String!, $account: String!) {
    otp_subscription(msisdn: $msisdn, otp: "") {
        subscription {
            billing(account: $account){
                billing_account_id
                debt
                credit_limit
                credit_limit_usage
                over_payment
                advance_payment
                unbilled
                deposits {
                    default
                }
            }
        }
        
    }
}
`;
export const GET_BALANCES = gql`
    query OtpSubscription($msisdn: String!) {
        otp_subscription(msisdn: $msisdn, otp: "") {
            subscription {
                all_balances {
                    is_main_balance
                    amount
					balance_name
                }
                
            }
       }
    },
`;
export const GET_BILLING_ACCOUNTS = gql`
    query GetBillingAccounts($msisdn: String!, $is_b2b_fmc: Boolean) {
        otp_subscription(msisdn: $msisdn, otp: "", is_b2b_fmc: $is_b2b_fmc) {
            subscription {
                id
                barrings(status: "active") {
                        id,
                        name {
                            ...languageFull
                        }
                        barring_level_codes
                    }
                billing_accounts {
                    barrings {
                        id,
                        name {
                            ...languageFull
                        }
                        barring_level_codes
                    }
                    status
                    billing_reports {
                        debt
                        main_balance
                        billing_account_status
                    }
                    type {
                        code
                        name
                        is_default
                    }
                    name
                    id   
                }
                
            }
       }
    },
    ${language.fragments.full}
`
;

export const SUBSCRIPTION_FMC_POSTPAID_QUERY = gql`
    query OtpSubscription($msisdn: String!, $otp: String!, $is_b2b_fmc: Boolean) {
        otp_subscription(msisdn: $msisdn, otp: $otp, is_b2b_fmc: $is_b2b_fmc) {
            subscription {
                id,
				fmc_merge_status,
                payment_type,
                is_portout_ongoing,
                is_temporary_msisdn,
                is_fttb_current_payment_status,
                is_fttb_first_payment_status,
                msisdn,
                ...OwnerContactPhone,
                status,
                ...ActivePackages,
                ...AvailablePackages,
			
                subscription_type {
					tags
                    ...tariffPlanDetails
                    subscription_type_category
                },
                is_tv_tuner_debt,
                owner {
                    ...Customer
                },
                billing_accounts {
                    status
                    type {
                        name
                    }
                }   
            }
       }
    },
    ${Customer},
    ${SubscriptionClient.fragments.activePackages},
    ${SubscriptionClient.fragments.availablePackages},
    ${TariffPlan.fragments.full},
    ${SubscriptionClient.fragments.ownerContactPhone}
`;

export const MNP_QUERY = gql`
    query OtpMnpSubscription($msisdn: String!, $otp: String!) {
        otp_subscription(msisdn: $msisdn, otp: $otp) {
            subscription {
                id
            }
        }
    }
`;
export const GET_FTTB_PLANS = gql`
    query Subscription($msisdn: String!) {
        otp_subscription(msisdn: $msisdn, otp: "") { 
            subscription {
                id,
                available_subscription_types(line_type: "${LineTypeEnum.Fmc.toUpperCase()}") {
                    ...tariffPlanDetails
                },
            }
       }
    },
    ${TariffPlan.fragments.full}
`;

export const GET_FMC_POSTPAID_PLANS = gql`
    query Subscription {
        subscription_types_by_type_category { 
            ...tariffPlanDetails
            subscription_type_category
       }
    },
    ${TariffPlan.fragments.full}
`;

// TODO UKR-16706
export const GET_TEMPORARY_MSISDN_SUBSCRIPTION = gql`
    query Subscription($msisdn: String!) {
        limited_subscription(msisdn: $msisdn) {
            id,
            subscription_exists,
            is_portout_ongoing,
            status,
            sim_card{
                icc
                sim_type
            },
            is_starterkit,
            is_prepaid,
       }
    },
`;

export const GET_PERSONALIZATION = gql`
    query Subscription($msisdn: String!, $otp: String!) {
        otp_subscription(msisdn: $msisdn, otp: $otp) {
            subscription {
                id,
                msisdn,
                msisdn_details {
                    msisdn,
                    number_category,
                },
                status,
                sim_card {
                    icc
                },
                subscription_type {
                    name {...languageFull}
                    payment_type,
                },
                owner {
                    ...Customer
                },
            }
       }
    },
    ${Customer},
    ${language.fragments.full}

`;

//TODO UKR-16714
export const GET_SUBSCRIPTIONS = gql`
    query Subscriptions($contact_phone: String!, $page: Int, $size: Int) {
        subscriptions(contact_phone: $contact_phone, page: $page, size: $size),  {
            id
            status
            subscription_type {
                line_type
            }
        }
    }`;

export const GET_DATES_OF_PORTATION = gql`
    query PortInDueDates {
        portin_duedates {
            due_date,
            time_start,
            time_end,
        }
    }
`;
