/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface ErrorMessagesType {
	anonymousNotAllowed: MessageDescriptor;
	baFraudBarring: MessageDescriptor;
	billingAccountNotActive: MessageDescriptor;
	currentPaymentStatus: MessageDescriptor;
	customerNotIdentified: MessageDescriptor;
	fastConnectionActive: MessageDescriptor;
	fastConnectionAndServiceMasterActive: MessageDescriptor;
	fmcNotAllowedForPostpaid: MessageDescriptor;
	fmcNotAllowedForPrepaid: MessageDescriptor;
	fmcNotPossible: MessageDescriptor;
	fmcPostpaidMergeForGsmSuspendedSubscriptionProhibited: MessageDescriptor;
	fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge: MessageDescriptor;
	fmcPostpaidOnlyActiveAndSuspenedFttbSubscriptionAllowedToMerge: MessageDescriptor;
	fttbPauseActive: MessageDescriptor;
	fttbPausePackage: MessageDescriptor;
	isTemporaryMsisdn: MessageDescriptor;
	isRouterWaitingForActivate: MessageDescriptor;
	missedBillingAccount: MessageDescriptor;
	msisdnPortingRequestOngoing: MessageDescriptor;
	noContactPhoneErorr: MessageDescriptor;
	noConvergentFttb: MessageDescriptor;
	noSubscriptionTypeOffering: MessageDescriptor;
	notAllowFmcMerge: MessageDescriptor;
	notAllowFmcMergeOngoingMerge: MessageDescriptor;
	notAllowFmcMergePreviousDone: MessageDescriptor;
	pageNotFound: MessageDescriptor;
	personalServicePkgNotActive: MessageDescriptor;
	previousFmcMergeFailed: MessageDescriptor;
	serviceMasterActive: MessageDescriptor;
	subFraudBarring: MessageDescriptor;
	subscriptionNotFoundForMsisdn: MessageDescriptor;
	tvTunerDebt: MessageDescriptor;
	unpairFmcFailed: MessageDescriptor;
	notStarterPack: MessageDescriptor;
}
const ErrorMessages: ErrorMessagesType = defineMessages({
	anonymousNotAllowed: {
		id: "fmc-not-allowed-for-anonymous",
		description: "Fmc validation error",
		defaultMessage: "FMC for anonymous not allowed."
	},
	baFraudBarring: {
		id: "fmc-flow-postpaid-billing-account-with-fraud-barring",
		description: "Fmc postpaid flow. BA  validation. Fraud barring",
		defaultMessage: "Billing accounts have fraud barring"
	},
	billingAccountNotActive: {
		id: "fmc-flow-postpaid-billing-account-not-active",
		description: "Fmc postpaid flow. BA  validation. Active allowed",
		defaultMessage: "Only active Billing Account allowed"
	},
	currentPaymentStatus: {
		id: "fmc-flow-postpaid-subscription-current-payment-status-false",
		description: "Fmc postpaid flow. fttb subscription current payment status should be true",
		defaultMessage: "FMC merge not allowed on FTTB as there are any debts on FTTB , pls check FTTB Current Payment status in FTTB subscription view"
	},
	customerNotIdentified: {
		id: "fmc-flow-b2b-customer-not-identified",
		description: "Fmc b2b flow. customer not identified",
		defaultMessage: "Identification by number failed, please execute identification in CSR and try again"
	},
	fastConnectionActive: {
		id: "fmc-flow-fast-connection-is-active-error",
		description: "FMC validation error Fast connection and Service master is active!",
		defaultMessage: "Fast connection is active!"
	},
	fastConnectionAndServiceMasterActive: {
		id: "fmc-flow-fast-connection-and-service-master-is-active-error",
		description: "FMC validation error Fast connection and Service master is active!",
		defaultMessage: "Fast connection and Service master is active!"
	},
	fmcNotAllowedForPostpaid: {
		id: "fmc-flow-not-possible-postpaid",
		description: "FMC not possible postpaid",
		defaultMessage: "FMC is not allowed for postpaid subscriptions. Please use Postpaid FMC."
	},
	fmcNotAllowedForPrepaid: {
		id: "fmc-flow-not-possible-for-prepaid",
		description: "FMC not possible prepaid",
		defaultMessage: "Currect FMC flow is not allowed for gsm prepaid subscriptions. Please use Prepaid fmc flow."
	},
	fmcNotPossible: {
		id: "fmc-flow-not-possible",
		description: "FMC not possible COEX temporary message on FMC validation",
		defaultMessage: "FMC is not possible with mobile number you entered"
	},
	fmcPostpaidMergeForGsmSuspendedSubscriptionProhibited: {
		id: "fmc-flow-postpaid-merge-for-gsm-suspended-subscription-prohibited",
		description: "Fmc postpaid flow. Gsm subscription validation. Merge for suspended,terminated,limited subscription prohibited",
		defaultMessage: "Only GSM subscription of the status active allow merging"
	},
	fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge: {
		id: "fmc-flow-postpaid-fttb-subbscription-only-active-allowed-to-merge",
		description: "Fmc postpaid flow. Fttb subscription validation. Merge for terminated,limited subscription prohibited",
		defaultMessage: "Only FTTB subscription of the status: Active allow merging"
	},
	fmcPostpaidOnlyActiveAndSuspenedFttbSubscriptionAllowedToMerge: {
		id: "fmc-flow-postpaid-fttb-subbscription-only-active-and-supened-allowed-to-merge",
		description: "Fmc postpaid flow. Fttb subscription validation. Merge for terminated,limited subscription prohibited",
		defaultMessage: "Only FTTB subscription of the status: Suspended and Active allow merging"
	},
	fttbPauseActive: {
		id: "fmc-flow-fttb-pause-active-validation-message",
		description: "fttb pause validation message in tariff plan during fmc merge",
		defaultMessage: "Fttb Pause Package is Active"
	},
	fttbPausePackage: {
		id: "fmc-flow-fttb-pause-validation-message",
		description: "fttb pause validation message in tariff plan during fmc merge modal",
		defaultMessage: "Fttb Pause Package"
	},
	isTemporaryMsisdn: {
		id: "fmc-flow-msisdn-is-temporary-msisdn-validation-erorr",
		description: "Is temporary msisdn validation error",
		defaultMessage: "Given MSISDN {msisdn} cannot be used as a part of FMC because it is being used as a temporary MSISDN within an ongoing MNP port-in process."
	},
	isRouterWaitingForActivate: {
		id: "fmc-flow-msisdn-is-router-waiting-for-activate-validation-erorr",
		description: "Is router waiting for activate validation error",
		defaultMessage: "FTTB subscription has router with waiting for active status"
	},
	missedBillingAccount: {
		id: "acquisition-existing-customer-flow-tariff-plan-missed-billing-account-type",
		description: "Acquisition existing customer flow, tariff plan missed billing account type",
		defaultMessage: "Configuration issue offerings.offering_subscription_types.subscription_types.billing_account_type is empty for tariff plan {name}"
	},
	msisdnPortingRequestOngoing: {
		id: "fmc-flow-msisdn-porting-request-ongoing-validation-erorr",
		description: "Msisdn porting request ongoing validation error",
		defaultMessage: "Msisdn {msisdn} porting request ongoing"
	},
	noContactPhoneErorr: {
		id: "fmc-flow-fttb-subscription-no-conttact-phone-error",
		description: "Fttb subscription label",
		defaultMessage: "Contact phone is missing for Fttb subscription"
	},
	noConvergentFttb: {
		id: "fmc-flow-no-convergent-fttb",
		description: "Configuration issue. No convergent fttb error msg",
		defaultMessage: "Configuration issue. Convergent fttb doesn't exist for tariff plan code: {code}"
	},
	noSubscriptionTypeOffering: {
		id: "fmc-flow-no-subscription-type-offering",
		description: " No subscription type offering",
		defaultMessage: "Configuration issue. Subscription type offering missed for Fttb subscription type. Fttb plan id : {id}"
	},
	notAllowFmcMerge: {
		id: "fmc-not-allowed",
		description: "Fmc validation error",
		defaultMessage: "FMC allowed for tariff plans containing the tag AllowFmcMerge or subscription type Category FmcPopMerge or  POP FMC ADDON."
	},
	notAllowFmcMergeOngoingMerge: {
		id: "fmc-not-allowed-ongoing-merge",
		description: "Fmc ongoing validation error",
		defaultMessage: "FMC merge is ongoing."
	},
	notAllowFmcMergePreviousDone: {
		id: "fmc-not-allowed-previous-done",
		description: "Fmc done validation error",
		defaultMessage: "FMC merge is already done"
	},
	pageNotFound: {
		id: "error-http-404",
		description: "error http 404",
		defaultMessage: "The page does not exist."
	},
	personalServicePkgNotActive: {
		id: "fmc-b2b-flow-personal-service-pkg-not-active",
		description: "Fmc b2b flow. personal service package not active",
		defaultMessage: "Please contact your company's coordinator"
	},
	previousFmcMergeFailed: {
		id: "fmc-not-allowed-previous-fmc-merge-failed",
		description: "The previous FMC merge failed",
		defaultMessage: "The previous FMC merge failed. MS operations team will take care of future actions."
	},
	serviceMasterActive: {
		id: "fmc-flow-service-master-is-active-error",
		description: "FMC validation error Fast connection and Service master is active!",
		defaultMessage: "Service master is active!"
	},
	subFraudBarring: {
		id: "fmc-flow-postpaid-subscription-with-fraud-barring",
		description: "Fmc postpaid flow. Subscription validation. Fraud barring",
		defaultMessage: "Subscription has fraud barring"
	},
	subscriptionNotFoundForMsisdn: {
		id: "main-search-subscription-not-found-msisdn",
		description: "Main view Subscription Not Found message",
		defaultMessage: "Subscription not found with the given MSISDN."
	},
	tvTunerDebt: {
		id: "fmc-flow-tv-tuner-debt",
		description: "FMC validation error tv tuner debt",
		defaultMessage: "TV tuner debt on FTTB subscription"
	},
	unpairFmcFailed: {
		id: "error-unpair-fmc-failed",
		description: "unpair fmc failed",
		defaultMessage: "Process of Fmc unpairing failed"
	},
	notStarterPack: {
		id: "error-not-starter-paclk",
		description: "not starter pack",
		defaultMessage: 'Temporary MSISDN is not starter pack, Please use MSISDN of starter pack in the "Idle" or "Active" statuses'
	},
});

export default ErrorMessages;
export { ErrorMessages };
