/* tslint:disable:max-line-length */
import { defineMessages, MessageDescriptor } from "react-intl";

// Interface has been generated automatically. Please do not edit manually.
// For changes run in console: npm run rebuild-messages-interfaces
interface CommonMessagesType {
	5000: MessageDescriptor;
	5010: MessageDescriptor;
	5020: MessageDescriptor;
	5030: MessageDescriptor;
	5040: MessageDescriptor;
	5050: MessageDescriptor;
	5060: MessageDescriptor;
	activate: MessageDescriptor;
	activated: MessageDescriptor;
	active: MessageDescriptor;
	additionInfoUpdateFail: MessageDescriptor;
	ageLimit: MessageDescriptor;
	amountOfDigits: MessageDescriptor;
	amountSymbols: MessageDescriptor;
	availablePackages: MessageDescriptor;
	b2bFttb: MessageDescriptor;
	back: MessageDescriptor;
	backDialog: MessageDescriptor;
	balance: MessageDescriptor;
	birthDayErrorMsg: MessageDescriptor;
	cancel: MessageDescriptor;
	capitalLetter: MessageDescriptor;
	changeIdentity: MessageDescriptor;
	changeMSISDN: MessageDescriptor;
	changeSIM: MessageDescriptor;
	changeTariffPlan: MessageDescriptor;
	chooseFile: MessageDescriptor;
	close: MessageDescriptor;
	companyIdMinAmount: MessageDescriptor;
	companyName: MessageDescriptor;
	confirm: MessageDescriptor;
	confirmChange: MessageDescriptor;
	confirmDialog: MessageDescriptor;
	contactInfo: MessageDescriptor;
	contractGenerationFail: MessageDescriptor;
	createNewSubscription: MessageDescriptor;
	createOrderFail: MessageDescriptor;
	createOrderSuccess: MessageDescriptor;
	customerExist: MessageDescriptor;
	customerManagement: MessageDescriptor;
	customerNew: MessageDescriptor;
	customerNotFound: MessageDescriptor;
	dateDocIssueErrorMsg: MessageDescriptor;
	digitsAndTextSymbols: MessageDescriptor;
	doYouWantToActivateMsisdn: MessageDescriptor;
	documentLengthMin: MessageDescriptor;
	documentUploadFail: MessageDescriptor;
	documentUploadSuccess: MessageDescriptor;
	done: MessageDescriptor;
	downloadContract: MessageDescriptor;
	error: MessageDescriptor;
	filesLimit: MessageDescriptor;
	find: MessageDescriptor;
	firstName: MessageDescriptor;
	fmcFlow: MessageDescriptor;
	frontPage: MessageDescriptor;
	fttbConnection: MessageDescriptor;
	icc: MessageDescriptor;
	iccValidationMax: MessageDescriptor;
	idle: MessageDescriptor;
	illegalSymbolsValidation: MessageDescriptor;
	inputFormat: MessageDescriptor;
	inputName: MessageDescriptor;
	inputOnlyDigits: MessageDescriptor;
	insufficientBalance: MessageDescriptor;
	intErrorMgs: MessageDescriptor;
	invalidIcc: MessageDescriptor;
	invalidMsisdn: MessageDescriptor;
	language: MessageDescriptor;
	lastName: MessageDescriptor;
	loading: MessageDescriptor;
	minimumAmountOfDigits: MessageDescriptor;
	msisdn: MessageDescriptor;
	msisdnCategory: MessageDescriptor;
	msisdnStartWith: MessageDescriptor;
	newCustomer: MessageDescriptor;
	newSubscription: MessageDescriptor;
	next: MessageDescriptor;
	noMatchesFound: MessageDescriptor;
	notMatchIcc: MessageDescriptor;
	oneTimeCharge: MessageDescriptor;
	oneTimeFee: MessageDescriptor;
	onlyDigits: MessageDescriptor;
	order: MessageDescriptor;
	orderId: MessageDescriptor;
	otp: MessageDescriptor;
	otpConfirmation: MessageDescriptor;
	otpRequestFail: MessageDescriptor;
	otpSuccess: MessageDescriptor;
	otpValidationError: MessageDescriptor;
	otpWasSent: MessageDescriptor;
	packages: MessageDescriptor;
	personalDetails: MessageDescriptor;
	personalise: MessageDescriptor;
	personalizationForNewCustomerFail: MessageDescriptor;
	personalizeForExistingCustomerFail: MessageDescriptor;
	personalizeStarterKit: MessageDescriptor;
	pollingChangeCustomerRequestFail: MessageDescriptor;
	pollingEditCustomerRequestFail: MessageDescriptor;
	postpaid: MessageDescriptor;
	prepaid: MessageDescriptor;
	prepaidFmc: MessageDescriptor;
	b2bFmc: MessageDescriptor;
	prepaidFttb: MessageDescriptor;
	previous: MessageDescriptor;
	productName: MessageDescriptor;
	provideValidIcc: MessageDescriptor;
	recipientFiles: MessageDescriptor;
	removeFile: MessageDescriptor;
	required: MessageDescriptor;
	reset: MessageDescriptor;
	searchMsisdn: MessageDescriptor;
	searchStarterKitOrSubscription: MessageDescriptor;
	searchSubscription: MessageDescriptor;
	sec: MessageDescriptor;
	selectDealerIdentity: MessageDescriptor;
	selectFromDropdownList: MessageDescriptor;
	selectPreviously: MessageDescriptor;
	sendOneTimePassword: MessageDescriptor;
	serverError: MessageDescriptor;
	serverFailed: MessageDescriptor;
	status: MessageDescriptor;
	submit: MessageDescriptor;
	subscription: MessageDescriptor;
	subscriptionNotFound: MessageDescriptor;
	tariffPlan: MessageDescriptor;
	textSymbols: MessageDescriptor;
	total: MessageDescriptor;
	transferBalance: MessageDescriptor;
	usedIcc: MessageDescriptor;
	userIdIsMissing: MessageDescriptor;
	userProfile: MessageDescriptor;
	validEmail: MessageDescriptor;
	validateOtp: MessageDescriptor;
	warning: MessageDescriptor;
	withOutPeriodicFee: MessageDescriptor;
	deliveryZone:MessageDescriptor;
}
const CommonMessages: CommonMessagesType = defineMessages({
	5000: {
		id: "error-change-msisdn-5000",
		description: "error_code from BE Msisdn already reserved/in use OR Someone already reserved that number.",
		defaultMessage: "Msisdn already reserved/in use OR Someone already reserved that number."
	},
	5010: {
		id: "error-change-msisdn-5010",
		description: "error_code from BE You cannot reserve same MSISDN twice",
		defaultMessage: "You cannot reserve same MSISDN twice"
	},
	5020: {
		id: "error-change-msisdn-5020",
		description: "error_code from BE MSISDN not found",
		defaultMessage: "MSISDN not found"
	},
	5030: {
		id: "error-change-msisdn-5030",
		description: "error_code from BE Please enter a valid MSISDN",
		defaultMessage: "Please enter a valid MSISDN"
	},
	5040: {
		id: "error-blocking-condition",
		description: "Error from BE if you try to do opetation, whicn is ongoing",
		defaultMessage: "Another ongoing operation is preventing the requested action"
	},
	5050: {
		id: "error-change-msisdn-5050",
		description: "error_code from BE Given MSISDN doesn't match to subscription",
		defaultMessage: "Given MSISDN doesn't match to subscription"
	},
	5060: {
		id: "error-change-msisdn-5060",
		description: "error_code from BE Your balance is low Please recharge to get this Number",
		defaultMessage: "Your balance is low Please recharge to get this Number"
	},
	activate: {
		id: "activate-action",
		description: "Activate view button",
		defaultMessage: "Activate"
	},
	activated: {
		id: "main-activated",
		description: "Main view label activated",
		defaultMessage: "Activated"
	},
	active: {
		id: "main-subscription-status-active",
		description: "Subscription status active",
		defaultMessage: "Active"
	},
	additionInfoUpdateFail: {
		id: "main-existing-customer-edit-user-additional-info",
		description: "Update user first name alt password language fail",
		defaultMessage: "Addition Info update fail"
	},
	ageLimit: {
		id: "main-age-limit-error-message",
		description: "Error message limit age of owner of sim card",
		defaultMessage: "Legal owner should be older than {age} years should be provided"
	},
	amountOfDigits: {
		id: "main-error-label-amount-of-digits",
		description: "Error message for validation field",
		defaultMessage: "Must be {amount} digits"
	},
	amountSymbols: {
		id: "main-amount-symbols",
		description: "Error message amount symbols",
		defaultMessage: "Should be symbols: {symbols}"
	},
	availablePackages: {
		id: "main-available-packages",
		description: "Main view Available packages",
		defaultMessage: "Available packages"
	},
	b2bFttb: {
		id: "main-page-b2b-fttb",
		description: "Main page b2b fttb button title",
		defaultMessage: "B2B FTTB"
	},
	back: {
		id: "main-label-back",
		description: "Label for button",
		defaultMessage: "Back"
	},
	backDialog: {
		id: "dialog-btn-back",
		description: "Dialog view button Back",
		defaultMessage: "Back"
	},
	balance: {
		id: "main-balance",
		description: "Main view balance",
		defaultMessage: "Balance"
	},
	birthDayErrorMsg: {
		id: "main-error-message-birth-day",
		description: "Error message birth day incorrect",
		defaultMessage: "Birthday is entered incorrectly"
	},
	cancel: {
		id: "main-title-cancel",
		description: "Title for button",
		defaultMessage: "Cancel"
	},
	capitalLetter: {
		id: "main-capital-letter",
		description: "Error message capital letter",
		defaultMessage: "Should be capital letter"
	},
	changeIdentity: {
		id: "main-toggle-dealer-identities-change-identity",
		description: "Dealer identities dropdown toogle",
		defaultMessage: "Change Identity"
	},
	changeMSISDN: {
		id: "main-change-msisdn",
		description: "Title change msisdn",
		defaultMessage: "Change MSISDN"
	},
	changeSIM: {
		id: "main-change-sim",
		description: "Title change sim",
		defaultMessage: "Change SIM"
	},
	changeTariffPlan: {
		id: "main-change-tariff-plan",
		description: "Title change tariff plan",
		defaultMessage: "Change PLAN"
	},
	chooseFile: {
		id: "main-choose-files",
		description: "Choose file",
		defaultMessage: "Choose file"
	},
	close: {
		id: "main-modal-label-close",
		description: "Label for button",
		defaultMessage: "Close"
	},
	companyIdMinAmount: {
		id: "main-company-id--min-length",
		description: "Error message for company id validation field",
		defaultMessage: "Company ID must be minimum {amount} digits"
	},
	companyName: {
		id: "main-company-name",
		description: "Qvantel",
		defaultMessage: "Qvantel"
	},
	confirm: {
		id: "main-confirm-button",
		description: "Label Confirm on Button",
		defaultMessage: "Confirm"
	},
	confirmChange: {
		id: "main-confirm-change-sim",
		description: "Title change sim",
		defaultMessage: "Confirm Change"
	},
	confirmDialog: {
		id: "dialog-btn-confirm",
		description: "Dialog view button Confirm",
		defaultMessage: "Confirm"
	},
	contactInfo: {
		id: "main-contact-info",
		description: "Label Contact Info",
		defaultMessage: "Contact Info"
	},
	contractGenerationFail: {
		id: "error-contract-generation-fail",
		description: "Contract generation fail",
		defaultMessage: "Contract generation failed"
	},
	createNewSubscription: {
		id: "main-create-new-subscription",
		description: "Main screen second title Search Starter Kit or Subscription",
		defaultMessage: "New mobile subscription"
	},
	createOrderFail: {
		id: "main-label-error-message-order-fail",
		description: "Error message acquisition flow",
		defaultMessage: "Order creation failed for {msisdn}, please try again"
	},
	createOrderSuccess: {
		id: "main-label-success-message-order-done",
		description: "Success message acquisition flow",
		defaultMessage: "Order is completed for {order}"
	},
	customerExist: {
		id: "main-customer-exist",
		description: "Text Customer Exist",
		defaultMessage: "Existing Customer"
	},
	customerManagement: {
		id: "main-customer-management-link",
		description: "Title for link to application Customer Management",
		defaultMessage: "Customer management"
	},
	customerNew: {
		id: "main-customer-new",
		description: "Error message for Not found customer",
		defaultMessage: "New Customer"
	},
	customerNotFound: {
		id: "main-customer-not-found",
		description: "Error message for Not found customer",
		defaultMessage: "Customer not found"
	},
	dateDocIssueErrorMsg: {
		id: "main-error-message-date-doc-issue",
		description: "Error message date of doc issue incorrect",
		defaultMessage: "Document Issue Date is entered incorrectly"
	},
	digitsAndTextSymbols: {
		id: "main-error-label-digits-letters",
		description: "Error message for validation",
		defaultMessage: "Input text and digits symbols"
	},
	doYouWantToActivateMsisdn: {
		id: "activation-activate-msisdn",
		description: "Activation Do you want to activate MSISDN? View label",
		defaultMessage: "Do you want to activate MSISDN?"
	},
	documentLengthMin: {
		id: "main-error-document-min-length",
		description: "Error message for document number validation field",
		defaultMessage: "Document number must be minimum {amount} character"
	},
	documentUploadFail: {
		id: "error-document-upload-fail",
		description: "Document upload fail",
		defaultMessage: "Document upload failed"
	},
	documentUploadSuccess: {
		id: "document-upload-success",
		description: "Document upload successfully",
		defaultMessage: "File(s) uploaded successfully"
	},
	done: {
		id: "main-label-btn-done",
		description: "Label on the button",
		defaultMessage: "Done"
	},
	downloadContract: {
		id: "main-upload-file",
		description: "Upload file link text",
		defaultMessage: "Download Contract"
	},
	error: {
		id: "main-error-message",
		description: "Main error message",
		defaultMessage: "Error"
	},
	filesLimit: {
		id: "main-files-limit-warning",
		description: "Label size of the files",
		defaultMessage: "Max size of the files should be {size} MB"
	},
	find: {
		id: "main-label-find",
		description: "Label for button",
		defaultMessage: "Find"
	},
	firstName: {
		id: "main-first-name",
		description: "Main view First Name",
		defaultMessage: "First Name"
	},
	fmcFlow: {
		id: "main-page-fmc-flow",
		description: "Title for FMC block",
		defaultMessage: "FMC"
	},
	frontPage: {
		id: "dialog-front-page",
		description: "Main page title",
		defaultMessage: "Front page"
	},
	fttbConnection: {
		id: "main-page-fttb-connection",
		description: "Tariff plan type prepaid",
		defaultMessage: "FTTB connection"
	},
	icc: {
		id: "main-icc",
		description: "Main view label icc",
		defaultMessage: "ICC"
	},
	iccValidationMax: {
		id: "main-icc-validation-max",
		description: "ICC max validation message",
		defaultMessage: "ICC should not be more then {max} chars"
	},
	idle: {
		id: "main-subscription-status-idle",
		description: "Subscription status idle",
		defaultMessage: "Inactive"
	},
	illegalSymbolsValidation: {
		id: "main-name-illegal-symbols-validation",
		description: "Error message capital letter",
		defaultMessage: "Field contains illegal characters."
	},
	inputFormat: {
		id: "main-input-format",
		description: "Error message format",
		defaultMessage: "Follow format: {format}"
	},
	inputName: {
		id: "main-error-label-input-name",
		description: "Error message for validation field",
		defaultMessage: "Use only text's symbols"
	},
	inputOnlyDigits: {
		id: "main-input-digits",
		description: "Placeholder number of digits only",
		defaultMessage: "{number} digits only"
	},
	insufficientBalance: {
		id: "main-msg-balance-is-insufficient",
		description: "Message for validation insufficient balalnce",
		defaultMessage: "Insufficient balance for requested operation"
	},
	intErrorMgs: {
		id: "main-error-message-int",
		description: "Error message int incorrect",
		defaultMessage: "Individual Tax Number is entered incorrectly"
	},
	invalidIcc: {
		id: "error-validation-icc-invalid",
		description: "Error from BE for trying change SIM",
		defaultMessage: "Invalid ICC, format is not correct"
	},
	invalidMsisdn: {
		id: "main-invalid-invalid-msisdn",
		description: "Message for validation msisdn",
		defaultMessage: "Msisdn is not valid"
	},
	language: {
		id: "common-language",
		description: "Common language",
		defaultMessage: "Language"
	},
	lastName: {
		id: "main-last-name",
		description: "Main view Last Name",
		defaultMessage: "Last Name"
	},
	loading: {
		id: "main-loading",
		description: "Loading text",
		defaultMessage: "Loading"
	},
	minimumAmountOfDigits: {
		id: "main-error-label-minimum-amount-of-digits",
		description: "Error message for validation field",
		defaultMessage: "Phone number must be minimum {amount} digits"
	},
	msisdn: {
		id: "main-msisdn",
		description: "Main view MSISDN",
		defaultMessage: "MSISDN"
	},
	msisdnCategory: {
		id: "main-msisdn-category",
		description: "Main view Msisdn Category",
		defaultMessage: "MSISDN Category"
	},
	msisdnStartWith: {
		id: "main-invalid-msisdn-start-with",
		description: "Message for validation msisdn",
		defaultMessage: "Msisdn should be started with {value}"
	},
	newCustomer: {
		id: "main-new-customer",
		description: "Label new customer",
		defaultMessage: "New customer"
	},
	newSubscription: {
		id: "main-page-button-new-subscription",
		description: "Label of button on main page",
		defaultMessage: "New subscription"
	},
	next: {
		id: "main-label-next",
		description: "Label for button",
		defaultMessage: "Next"
	},
	noMatchesFound: {
		id: "main-no-matches-found",
		description: "No matches found. typehead msg",
		defaultMessage: "No matches found."
	},
	notMatchIcc: {
		id: "error-validation-icc-not-match",
		description: "Error from BE for trying change SIM",
		defaultMessage: "Invalid ICC, doesn't match to subscription"
	},
	oneTimeCharge: {
		id: "main-one-time-charge",
		description: "One time charge label ",
		defaultMessage: "One time charge"
	},
	oneTimeFee: {
		id: "main-label-one-time-fee",
		description: "Label One time fee",
		defaultMessage: "One time fee"
	},
	onlyDigits: {
		id: "main-error-label-only-digits",
		description: "Error message for validation field",
		defaultMessage: "Input only digits symbols"
	},
	order: {
		id: "main-title-order",
		description: "Title for button",
		defaultMessage: "Order"
	},
	orderId: {
		id: "order-response-confirmation-code",
		description: "Order response confirmation code",
		defaultMessage: "Order ID: {confirmationCode}"
	},
	otp: {
		id: "main-otp",
		description: "Input field label One-time Password",
		defaultMessage: "One-time Password"
	},
	otpConfirmation: {
		id: "main-otp-confirmation",
		description: "Validate otp input placeholder ",
		defaultMessage: "One-time Password"
	},
	otpRequestFail: {
		id: "main-otp-reques-fail",
		description: "Otp reques failed general message",
		defaultMessage: "Otp request failed"
	},
	otpSuccess: {
		id: "main-otp-validation-sucess",
		description: "Successful OTP validation general message",
		defaultMessage: "Successful OTP validation"
	},
	otpValidationError: {
		id: "main-otp-validation-error-general",
		description: "Otp Validation Error general message",
		defaultMessage: "Failed the one-time password validation!"
	},
	otpWasSent: {
		id: "main-otp-was-sent",
		description: "Otp was sent msg",
		defaultMessage: "The one-time password was sent by SMS message to mobile phone."
	},
	packages: {
		id: "main-packages",
		description: "Main view Packages",
		defaultMessage: "Packages"
	},
	personalDetails: {
		id: "main-personal-details",
		description: "Main view Personal details",
		defaultMessage: "Personal details"
	},
	personalise: {
		id: "main-personalise",
		description: "Registration form header/Personalize subscription button",
		defaultMessage: "Personalize"
	},
	personalizationForNewCustomerFail: {
		id: "main-personalize-for-new-customer-fails",
		description: "BE fails Personalize request for existing customer fails",
		defaultMessage: "Personalization for new customer fail"
	},
	personalizeForExistingCustomerFail: {
		id: "main-personalize-for-existing-customer-fails",
		description: "BE fails Personalize request for existing customer fails",
		defaultMessage: "Personalization for existing customer fail"
	},
	personalizeStarterKit: {
		id: "main-personalize-starter-kit-title",
		description: "Personalize Starter Kit",
		defaultMessage: "Personalize Starter Kit"
	},
	pollingChangeCustomerRequestFail: {
		id: "main-polling-change-customer-request-fail",
		description: "Polling personalize_for_existing_customer mutation request status fails",
		defaultMessage: "Personalize for existing customer request fails"
	},
	pollingEditCustomerRequestFail: {
		id: "main-polling-edit-customer-request-fail",
		description: "Edit customer request status fails",
		defaultMessage: "Edit customer request fails"
	},
	postpaid: {
		id: "main-tariff-plan-type-postpaid",
		description: "Tariff plan type postpaid",
		defaultMessage: "Postpaid"
	},
	prepaid: {
		id: "main-tariff-plan-type-prepaid",
		description: "Tariff plan type prepaid",
		defaultMessage: "Prepaid"
	},
	prepaidFmc: {
		id: "main-page-fmc-flow-prepaid-button",
		description: "Title for FMC button",
		defaultMessage: "Prepaid FMC"
	},
	b2bFmc: {
		id: "main-page-fmc-flow-b2b-fmc-button",
		description: "Title for B2B FMC button",
		defaultMessage: "B2B FMC"
	},
	prepaidFttb: {
		id: "main-page-prepaid-fttb",
		description: "Main page button title",
		defaultMessage: "Prepaid FTTB"
	},
	previous: {
		id: "main-label-previous",
		description: "Label for previous button",
		defaultMessage: "Previous"
	},
	productName: {
		id: "main-product-name",
		description: "iPos",
		defaultMessage: "iPos"
	},
	provideValidIcc: {
		id: "error-validation-provide-valid-icc",
		description: "Error from BE for trying change SIM",
		defaultMessage: "Please provide a valid ICC number"
	},
	recipientFiles: {
		id: "main-recipient-files",
		description: "File upload block title",
		defaultMessage: "Attachments"
	},
	removeFile: {
		id: "main-remove-file",
		description: "Remove file",
		defaultMessage: "Remove"
	},
	required: {
		id: "main-form-field-required",
		description: "Message for field is required",
		defaultMessage: "Required"
	},
	reset: {
		id: "main-label-rest",
		description: "Label for button",
		defaultMessage: "Reset"
	},
	searchMsisdn: {
		id: "main-search-placeholder",
		description: "Placeholder for input field",
		defaultMessage: "Search MSISDN"
	},
	searchStarterKitOrSubscription: {
		id: "main-search-starter-kit-or-subscription",
		description: "Main screen first title Search Starter Kit or Subscription",
		defaultMessage: "Search Starter Kit or subscription"
	},
	searchSubscription: {
		id: "main-search-subscription-title",
		description: "Label for field search subscription",
		defaultMessage: "Search subscription"
	},
	sec: {
		id: "main-otp-time-left-seconds",
		description: "Secconds otp time left",
		defaultMessage: "sec"
	},
	selectDealerIdentity: {
		id: "main-toggle-select-dealer-identities-modal-title",
		description: "Dealer identities modal title",
		defaultMessage: "Please choose Dealer Identity"
	},
	selectFromDropdownList: {
		id: "main-form-field-select-from-dropdown-list",
		description: "Message for field is select from dropdown list",
		defaultMessage: "Please select a value from the dropdown list."
	},
	selectPreviously: {
		id: "main-label-depends-field-by",
		description: "Helper text for depends selector",
		defaultMessage: "Select {field} first"
	},
	sendOneTimePassword: {
		id: "main-send-otp",
		description: "Send One-time Password button text",
		defaultMessage: "Send One-time Password"
	},
	serverError: {
		id: "main-error-with-server",
		description: "Label for button",
		defaultMessage: "Error with server, try again"
	},
	serverFailed: {
		id: "main-error-with-server-failed",
		description: "Status of error for server",
		defaultMessage: "Server has rejected this action, retrying won't probably help. Contact customercare for information."
	},
	status: {
		id: "main-status",
		description: "Main view status",
		defaultMessage: "Status"
	},
	submit: {
		id: "main-title-submit",
		description: "Title for button",
		defaultMessage: "Submit"
	},
	subscription: {
		id: "main-subscription",
		description: "Subscription title",
		defaultMessage: "Subscription"
	},
	subscriptionNotFound: {
		id: "main-search-subscription-not-found",
		description: "Main view Subscription Not Found message",
		defaultMessage: "Subscription not found"
	},
	tariffPlan: {
		id: "main-tariffPlan",
		description: "Acquisition flow summary",
		defaultMessage: "Tariff Plan"
	},
	textSymbols: {
		id: "main-error-label-text-letters",
		description: "Error message for validation",
		defaultMessage: "Input only text symbols"
	},
	total: {
		id: "main-label-total-proce",
		description: "Label text",
		defaultMessage: "Total"
	},
	transferBalance: {
		id: "main-transfer-balance",
		description: "Main view Transfer balance",
		defaultMessage: "Transfer balance"
	},
	usedIcc: {
		id: "error-validation-icc-used",
		description: "Error from BE for trying change SIM",
		defaultMessage: "ICC already in use"
	},
	userIdIsMissing: {
		id: "main-existing-customer-user-id-is-missing",
		description: "User id is missing on subscription request",
		defaultMessage: "User id is missing on subscription request"
	},
	userProfile: {
		id: "main-toggle-user-profile",
		description: "label User Profile",
		defaultMessage: "User Profile"
	},
	validEmail: {
		id: "main-valid-email-format",
		description: "Message for validation email",
		defaultMessage: "Please enter a valid email: example@email.com"
	},
	validateOtp: {
		id: "main-validate-otp",
		description: "Validate otp button title",
		defaultMessage: "Validate"
	},
	warning: {
		id: "main-message-warning",
		description: "Label with text warning",
		defaultMessage: "Warning"
	},
	withOutPeriodicFee: {
		id: "main-with-out-periodic-fee",
		description: "Label 'Without Periodic Fee'",
		defaultMessage: "Without Periodic Fee"
	},
	deliveryZone: {
		id: "fttb-flow-delivery-zone",
		description: "Delivery zone place holder in billing accounts during fttb flow",
		defaultMessage: "Please select delivery zone"
	}
});

export default CommonMessages;
export { CommonMessages };
