import React, { FC, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from "react-router";
import { CircularProgress } from "../../../../shared/components";
import { useQuery } from "@apollo/react-hooks";
import { Query, QueryMsisdnArgs, SubscriptionTypeCategoryEnum, LanguageEnum, GisHouseGreen_FieldArgs, QueryGis_HousesArgs, SubscriptionType, Maybe } from "../../../../graphql/types";
import { SelectedTariffPlanDetails } from "../../../../graphql/localTypes";
import { FormattedMessage, useIntl } from "react-intl";
import { FTTBMessages } from "../../../fttb/FTTB.messages";
import { TableRowList } from "../../../change-bar/plan/ChangePlanRowList";
import { GET_FMC_POSTPAID_PLANS } from "../../../../graphql/queries/subscription";
import { StepWrapper } from "../../../../shared/components/navigation/StepWrapper";
import { FmcPostpaidRouteState } from "../../postpaid/fmc.postpaid.types";
import { useNavigationState } from "../../../../shared/components/navigation/useNavigationState";
import { GET_HOUSE_GREEN_FIELD } from "../../../../graphql/queries/gisSystem";

const Plan: FC<RouteComponentProps<never, StaticContext, FmcPostpaidRouteState>> = props => {
	const {locale} = useIntl();
	const state = useNavigationState<FmcPostpaidRouteState>();
	const location = useLocation();

	let isB2bFmc = false;
	if (location.pathname === "/fmc-b2b-flow/plan") {
		isB2bFmc = true;
	}

	const [fmcState, setFmcState] = useState<FmcPostpaidRouteState|undefined>(state);

	const {data, loading} = useQuery<Required<Pick<Query, "subscription_types_by_type_category">>, QueryMsisdnArgs>(GET_FMC_POSTPAID_PLANS, {
		fetchPolicy: "cache-and-network",
	});

	const {data: gisHouses, loading: gisHouseLoading } = useQuery<Required<Pick<Query, "gis_houses">>, GisHouseGreen_FieldArgs & QueryGis_HousesArgs>(GET_HOUSE_GREEN_FIELD, {
		variables: {
			language: locale as LanguageEnum,
			house_id: state?.preorder?.address?.gisHouseId!,
			apartment: state?.preorder?.address?.flat
		},
	});

	const handleSelectTariffPlan = (selectedFmcTariffPlan: SelectedTariffPlanDetails) => {
		setFmcState({
			...state,
			addons: undefined,
			plan: {
				selectedFmcTariffPlan,
			}
		});
	};

	useEffect(() => {
		if (state?.networkType) {
			setFmcState({
				...state,
				...fmcState,
				networkType: state?.networkType
			});
		}
	}, [state?.networkType])
	
	// const unpair = new Map();
	// unpair.set(true, [LineTypeEnum.Fmc, LineTypeEnum.Mobile]);
	// unpair.set(false, [LineTypeEnum.Fmc]);

	// const predicate = (item) => unpair.get(!!searchStep?.unpairFmc).includes(item?.line_type);
	const greenFieldTags = gisHouses?.gis_houses?.green_field?.green_field_tp;
	const greenFieldTpStatus = gisHouses?.gis_houses?.green_field?.green_field_tp_status;

	const gsmCode = fmcState?.search?.gsmSubscription?.subscription_type?.code;

	let subscriptionTypeCategory = SubscriptionTypeCategoryEnum.Fmcpopmerge;
	if (isB2bFmc) {
		subscriptionTypeCategory = SubscriptionTypeCategoryEnum.FMCB2BPopMerge;
	}
	
	let tariffPlans: Maybe<SubscriptionType>[] | undefined = [];
	if (state?.search?.withoutFttb) {
		if (greenFieldTpStatus === 1) {
			tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
				item?.subscription_type_category === subscriptionTypeCategory &&
				item?.code !== gsmCode && !greenFieldTags?.some(tag => item?.tags?.includes(tag))
			);
		} else if (greenFieldTpStatus === 2) {
			tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
				item?.subscription_type_category === subscriptionTypeCategory &&
				item?.code !== gsmCode && item.tags?.every(tag => greenFieldTags?.includes(tag) || !tag?.startsWith("Greenfield_"))
			);
		} else {
			if (isB2bFmc) {
				tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
					item?.subscription_type_category === subscriptionTypeCategory &&
					item?.code !== gsmCode && item.tags?.includes("Eligible_B2B_FMC_Merge")
				);
			}
			else {
				tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
					item?.subscription_type_category === subscriptionTypeCategory &&
					item?.code !== gsmCode
				);
			}
		}
	} else {
		if (isB2bFmc) {
			tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
				item?.subscription_type_category === subscriptionTypeCategory &&
				item?.code !== gsmCode && item.tags?.includes("Eligible_B2B_FMC_Merge")
			);
		} else {
			tariffPlans = data?.subscription_types_by_type_category?.filter(item =>
				item?.subscription_type_category === subscriptionTypeCategory &&
				item?.code !== gsmCode
			);
		}
	}

	const selectedCode = fmcState?.plan?.selectedFmcTariffPlan?.code;
	return (
		<StepWrapper<FmcPostpaidRouteState>
			isValid={!!fmcState?.plan?.selectedFmcTariffPlan.code && (selectedCode !== gsmCode)}
			newState={fmcState}
			previousStepStateKey={"search"}>
			<div className="row">
				<div className="col-12">
					{!loading && !(tariffPlans?.length && tariffPlans.length > 0) &&
						<div><FormattedMessage {...FTTBMessages.plansNotFound} /></div>}
					{tariffPlans?.map(subscription_types => subscription_types?.code &&
								(<TableRowList
									balance={Infinity}
									key={subscription_types?.code + "top-level"}
									tariffPlan={subscription_types}
									selectTariffPlan={handleSelectTariffPlan}
									selected={selectedCode === subscription_types.code}
								/>))}
					{loading && (<CircularProgress/>)}
				</div>
			</div>
		</StepWrapper>
	);
};

export {
	Plan,
};
