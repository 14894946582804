import { IntlShape } from "react-intl";
import {
	DeliveryTypeEnum,
	SalesTypeEnum,
	ProductTypeEnum,
	OrderFmcInput,
	Mutation, MutationCreate_Fmc_OrderArgs, Package, Maybe
} from "../../../graphql/types";
import { FmcRouteState } from "../fmc.type";
import { isRequestSuccess } from "../../../shared/utils/utils";
import { FMCMessages } from "../FMC.messages";
import { CommonMessages } from "../../../Common.messages";
import { MutationTuple } from "@apollo/react-hooks";
import { errorActions, StepActionPayload } from "../../../shared/components/navigation/StepStateProvider";
import { FMC_MERGE_ALREADY_CONVERTED_ERROR_MESSAGE, FMC_MERGE_FTTB_SUBSCRIPTION_ERROR_MESSAGE, FMC_MERGE_PRODUCT_WAITING_ERROR_MESSAGE } from "../../../config/constants";


const getTranslatedError = (activePackages:  Maybe<Array<Maybe<Package>>>|undefined, error: string, intl: IntlShape): string => {
	// If error contains FMC_MERGE_PRODUCT_WAITING_ERROR_MESSAGE then
	// check and return a matching package from subscriptio's active packages
	// sample error message:
	// fttb_subscription_id:Merge is not possible, subscription has product in waiting status: HOMETVTUNER1.
	if (error?.includes(FMC_MERGE_PRODUCT_WAITING_ERROR_MESSAGE)) {
		const name = activePackages?.find(pkg => pkg && error.includes(pkg.code!))?.name?.[intl.locale] || "";
		return intl.formatMessage(FMCMessages.mergeNotPossibleError, { name })
	} if(error.includes(FMC_MERGE_ALREADY_CONVERTED_ERROR_MESSAGE)
		&& error.includes(FMC_MERGE_FTTB_SUBSCRIPTION_ERROR_MESSAGE)) {
		return intl.formatMessage(FMCMessages.fttbSubscriptionIsAlreadyConvertedToFMCError);
	}
	//default error
	return error;
};
class FmcService {
	static handleFmcOrder(
		createOrder: MutationTuple<Mutation, MutationCreate_Fmc_OrderArgs>[0],
		state: Pick<FmcRouteState, "gsmSubscription"|"fttbSubscription"|"selectedFmcTariffPlan"|"bothGSMFTTBHaveActiveSBPackage"|"retainedPackage"|"deactivatePackage">,
		options: string[],
		setSuccess: React.Dispatch<React.SetStateAction<string[]>>,
		success: string[],
		intl: IntlShape,
		dispatch: React.Dispatch<StepActionPayload>,
		reseller_code: string|null|undefined,
		postpaid: boolean|null|undefined,
		postpaidType?: string | undefined
	): Promise<Mutation|void> {
		let salesType = "";
		if (postpaid) {
			if (postpaidType === "b2b") {
				salesType = SalesTypeEnum.B2bPostpaidFmc;
			} else {
				salesType = SalesTypeEnum.B2cPostpaidFmc;
			}
		} else {
			salesType = SalesTypeEnum.FmcMerge;
		}
		return createOrder({
			variables: {
				details: {
					delivery_type: DeliveryTypeEnum.Direct,
					sales_info: {
						sales_type: salesType,
						reseller_code,
					},
					products: {
						subscription: {
							mobile_subscription_id: state.gsmSubscription?.id,
							fttb_subscription_id: state.fttbSubscription?.id,
							type: state?.selectedFmcTariffPlan?.code,
							product_type: ProductTypeEnum.Subscription,
							packages: options,
							deactivate_packages: postpaid ? undefined : state.bothGSMFTTBHaveActiveSBPackage ? [state.deactivatePackage?.code] : undefined,
						},
					},
				} as OrderFmcInput,
			},
		})
			.then<Mutation>(({ data }) => {
				const status = data?.create_fmc_order?.status;
				if (status && isRequestSuccess(status)) {
					setSuccess([...success,
						intl.formatMessage(
							{ ...FMCMessages.successFmcConnection },
							{ gsmMsisdn: state?.gsmSubscription?.msisdn }
						)]
					);
				} else if (data?.create_fmc_order?.error) {
					const error = data?.create_fmc_order?.error;
					if (error.includes("OPTION82")) {
						throw intl.formatMessage({...FMCMessages.errorOption82});
					} else {
						throw getTranslatedError(state.fttbSubscription?.active_packages, error, intl);
					}
				}
				if (!data?.create_fmc_order) {
					throw intl.formatMessage(
						{ ...CommonMessages.createOrderFail },
						{
							msisdn: intl.formatMessage({
								...FMCMessages.fmcConnection,
							}),
						}
					);
				}
				return data;
			})
			.catch((ex) => {
				dispatch(errorActions.setError(ex));
			});
	}
}
export { FmcService };
