import {
	DeliveryTypeEnum,
	SalesTypeEnum,
	IdTypeEnum,
	FttbConnectionTypeEnum,
	ProductTypeEnum,
	Package,
	Mutation, MutationCreate_Fttb_OrderArgs, SubscriptionType
} from "../../../graphql/types";
import { FttbRouteState, FttbOptionState } from "../fttb.types";
import { config } from "../../../config/constants";
import CommonMessages from "../../../Common.messages";
import { FTTBMessages } from "../FTTB.messages";
import { isRequestSuccess } from "../../../shared/utils/utils";
import { SelectedTariffPlanDetails } from "../../../graphql/localTypes";
import { MutationTuple } from "@apollo/react-hooks";
import moment from "moment";
import { REMOVE_SPECIAL_CHARACTER } from "../../../shared/utils";
import { IntlShape } from "react-intl";
const FTTB_FAIL = "FTTB_FAIL";
class FttbService {
	static async handleFttbOrder(
		createOrder: MutationTuple<Required<Pick<Mutation, "create_fttb_order">>, MutationCreate_Fttb_OrderArgs>[0],
		state: FttbRouteState,
		intl: IntlShape,
		options: string[],
		setSuccess: React.Dispatch<React.SetStateAction<string>>,
		runSpinner: React.Dispatch<React.SetStateAction<boolean>>,
		reseller_code: string|undefined|null,
		deactivatePackage?: string[]
	): Promise<string|void> {
		const comment = state.options?.comment;
		const confirmationCode = state?.confirmation_code!.replace(REMOVE_SPECIAL_CHARACTER, "");
		return createOrder({
			variables: {
				details: {
					complex_sale: Boolean(state.options?.complexOffer),
					need_starterkit: Boolean(state.options?.starterPack),
					offer: state?.selectedFttbTariffPlan?.offerCode!,
					confirmation_code: confirmationCode,
					delivery_type: DeliveryTypeEnum.Direct,
					sales_info: {
						sales_type: SalesTypeEnum.FttbAcquisition,
						reseller_code,
					},
					desired_connection_date: moment.parseZone(state.options?.desiredConnectionDate).local(true).format(),
					customer: {
						first_name: state.first_name,
						last_name: state.last_name,
						middle_name: state.middle_name,
						nationality: "UA",
						language: intl.locale,
						is_company: false,
						id_number: "",
						is_anonymous_customer: true,
						id_type: IdTypeEnum.Passport,
						title: "male",
						address: {
							street: state.address?.gisStreetId,
							country: config.DEFAULT_COUNTRY!,
							postal_code: state.address?.gisZipId,
							city: state.address?.gisCityId,
							house_number: state.address?.gisHouseId,
							apartment: state.address?.flat,
							contact_phone: state?.contact_phone!,
							province: state?.address?.gisProvinceId,
						},
					},
					products: {
						subscription: {
							...(Boolean(comment) ? {comment}: {}),
							type: state?.selectedFttbTariffPlan?.code!,
							msisdn: state?.msisdn!,
							product_type: ProductTypeEnum.Subscription,
							packages: options,
							deactivate_packages: deactivatePackage,
							reservation_id: state?.confirmation_code!,
							...(state.housePreviousActivationStatus
								? {
									fttb_connection_type: state.address
										?.simpleConnection
										? FttbConnectionTypeEnum.Simple
										: FttbConnectionTypeEnum.Normal,
								  }
								: {}),
						},
					},
					by_pass_telemarketing: true,
				},
			},
		}).then(({ data }) => {
			const status = data?.create_fttb_order?.status;
			if (status && isRequestSuccess(status)) {
				setSuccess(
					intl.formatMessage(
						{ ...CommonMessages.createOrderSuccess },
						{
							order: `${state?.msisdn} ${intl.formatMessage({
								...FTTBMessages.fttbConnection,
							})}`,
						}
					)
				);
				runSpinner(false);
			} else if (data?.create_fttb_order?.error) {
				throw data?.create_fttb_order?.error;
			}
			if (!data?.create_fttb_order) {
				throw intl.formatMessage(
					{ ...CommonMessages.createOrderFail },
					{
						msisdn: intl.formatMessage({
							...FTTBMessages.fttbConnection,
						}),
					}
				);
			}
		})
	}

	static addFttbFastIntoOptionsIfPhisicalWireDayIsToday(fttbOptions: FttbOptionState|undefined|null): { options: string[]; isToday: boolean; bundles: Package[] }  {
		let bundles = fttbOptions?.packages || [];
		const initDate = fttbOptions?.desiredConnectionDate ? new Date(fttbOptions?.desiredConnectionDate) : undefined;
		const isToday = initDate?.getDate() === new Date().getDate();
		if (isToday) {
			bundles = bundles?.concat(fttbOptions?.fttbFast ? fttbOptions?.fttbFast : { code: config.FAST_CONNECTION_PACKAGE_CODE, name: { en: config.FAST_CONNECTION_PACKAGE_CODE }});
		}

		const options: Array<string> = bundles?.map(item => item.code!) || [];
		return { options, isToday, bundles };
	}


	static addTariffPlanOptionGroupCodeIntoOptionsIfItExist(selectedFttbTariffPlan: SelectedTariffPlanDetails|undefined, options: string[]) {
		const selectedOptionCode = selectedFttbTariffPlan?.selectedOptionCode;
		if (selectedOptionCode) {
			options.push(selectedOptionCode);
		}
	}

	static gigaBitService(options: FttbOptionState | undefined | null, isCramerCheckOk: boolean, simpleConnection: boolean) {
		if (!options || !options.packages) {
			return options;
		}
		options.packages.forEach(bundle => {
			if (bundle?.tags?.includes(config.GIGABIT_TAG)) {
				if (bundle.tags.includes(config.FTTB_PAID_GIGABIT_TAG)) {
					if (simpleConnection && !isCramerCheckOk) {
						const discountPack = bundle.provisioning_parameters?.find(params => params?.code === config.MappedDiscountPackage);
						if (discountPack) {
							bundle.code = discountPack.raw_value;
						}
					}
				} else if (bundle.tags.includes(config.FREE_GIGABIT_TAG)) {
					bundle.code = config.SBLeonardoDISCOUNTPACK;
				}
			}
		});
		return options;
	}



}
export { FttbService , FTTB_FAIL};
