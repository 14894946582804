const config = {
	VERSION: process.env.VERSION,
	CHANNEL: "POS_INDIRECT",
	CURRENCY_LOCALE: process.env.REACT_APP_CURRENCY_LOCALE || "de-DE",
	DEFAULT_ICC_PREFIX: process.env.REACT_APP_ICC_PREFIX || "89996",
	DEFAULT_COUNTRY_NAME: "Ukraine",
	PASSWORD_MIN_LENGTH: process.env.REACT_APP_PASSWORD_MIN_LENGTH || 8,
	DEFAULT_MSISDN_PREFIX: process.env.REACT_APP_MSISDN_PREFIX,
	DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE || "en",
	DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY,
	DEFAULT_COUNTRY: process.env.REACT_APP_DEFAULT_COUNTRY || "UA",
	GIS_COUNTRY_ID: "1",
	STARTER_KIT_NAME: process.env.REACT_APP_STARTERKIT || "starterkit",
	UPLOAD_FILE_TYPE: process.env.REACT_APP_UPLOAD_FILE_TYPE || "application/pdf,image/x-png,image/jpeg",
	MSISDN_SEARCH_PAGE_SIZE: process.env.REACT_APP_MSISDN_SEARCH_PAGE_SIZE || 45,
	PREDEFINED_TRANSFER_BALANCE_AMOUNT: JSON.parse(process.env.REACT_APP_PREDEFINED_TRANSFER_BALANCE_AMOUNT || ""),
	LANGUAGES: ["en", "uk", "ru"],
	MSISDN_NORMAL_CATEGORY: 0,
	MSISDN_FTTB_STOCK: 10,
	MSISDN_B2B_FTTB_STOCK: 20,
	MSISDN_NORMAL_STOCK: 0,
	HOST: process.env.NODE_ENV === "development" ? "localhost:8888" : window.location.host,
	REPEAT_REQUEST: 4,
	PERSONALIZATION: "personalization",
	PASSPORT: "passport",
	DATE_PICKER_FORMAT: process.env.REACT_APP_DATE_PICKER_FORMAT || "DD.MM.YYYY",
	DATE_TIME_FORMAT:  "DD.MM.YYYY HH:mm",
	FAST_CONNECTION_PACKAGE_CODE:  "FTTBFAST",
	SERVICE_MASTER_PACKAGE_CODE:  "FTTBSERVICEMASTER",
	FTTB_PAUSE:  "FTTBPAUSE",
	MIN_DATE_FORM: "1900",
	VALID_YEARS_OLD: "16",
	DATE_STEP: 50,
	TIME_FORMAT: "hh:mm:ss",
	SIZE_5_MB: 5000000,
	ITN_LENGTH: 10,
	INDIVIDUAL_TAX_NUMBER: 10,
	ID_DOCUMENT_MIN_LIMIT: 8,
	NATIONAL_ID: 9,
	PASSPORT_LENGTH: 8,
	PASSPORT_FORMAT: "AA123456",
	minYear: "01.01.1900",
	DEFAULT_INPUT_LENGTH: 255,
	ID_CARD_LENGTH: 9,
	PHONE_LENGTH: 12,
	PHONE_FORMAT: "380XXYYYYYYY",
	INDIVIDUAL_TAX_NUMBER_EMPTY: "XXXXXXXXXX",
	NOT_ALLOWED_FOR_SIMPLE_CONNECTION_PACKAGE_TAGS: ["CMSANDCS_activation", "CMSANDCS", "not_allowed_simple_connection"],
	REMOVE_FLAG_OFFER_PACKAGE: "PaymentStatusFlag",
	LOCALE: "uk-UA",
	BILLING_TYPE_CODE: "11",
	PACK_TV_TAG: "pack_tv",
	FTTBFAST_AVAILABLE_TIME_RANGE: {start: "00:00:00", end: "15:00:00"},
	POSTPAID_ON: true,
	SUPERPOWER_TAG: "provision_on_rc_payment",
	SUPERPOWER_FAMILY: "SUPERPOWER",
	HOME_TV_FAMILY: "Home TV",
	FTTB_PAID_GIGABIT_TAG: "FTTBGIGABITPAID",
	FMC_PAID_GIGABIT_TAG: "FMCGIGABITPAID",
	FREE_GIGABIT_TAG: "SBGIGABIT",
	GIGABIT_TAG: "GIGABIT",
	SBLeonardoDISCOUNTPACK: "SBFTTBDEMOGIGABIT",
	MappedDiscountPackage:"MappedDiscountPackage",
	SBPACKAGEIDENTIFIERTAGS: ["demo","provision_on_rc_payment"],
	FTTB_TAG: "FTTB",
	GPON_TAG: "GPON",
	GPON_ONU_TAG: "GPONONU"
};
export const BSSAPI_DATE_FORMAT = "YYYY-MM-DD";

export const VALID_MSISDN_PREFIXES = "(067|068|096|097|098|063|093|073|050|066|095|099|091|094)[0-9]{7}";
export const RECURRENT = "recurrent";
export const SINGLE = "single";
export const ICC_LENGTH = 18;
export const STORAGE_KEY_TOKEN = "token";
export const STORAGE_KEY_IS_AUTHENTICATED = "is-authenticated";
export const STORAGE_KEY_USER_NAME = "userName";
export const STORAGE_KEY_DEALER_IDENTITIES = "DealerIdentities";
export const STORAGE_KEY_CURRENT_DEALER_IDENTITY = "current-dealer-identity";
export const STORAGE_KEY_REFRESH_TOKEN = "refreshToken";
export const STORAGE_KEY_TOKEN_EXPIRE_IN = "tokenExpireIn";
export const MSISDN = "msisdn";
export const MSISDNS = "msisdns";
export const RESERVED_MSISDNS = "reserved_msisdns";
export const BILLING = "billing";
export const BILLING_STATUS = "billing_status";
export const ID_TYPE = "id_type";
export const ID_NUMBER = "id_number";
export const CUSTOMER = "customer";
export const CUSTOMER_INFO = "customer_info";
export const BILLING_EXISTING_ACCOUNT = "existing";
export const BILLING_NEW_ACCOUNT = "new";
export const ICC = "icc";
export const ACTIVE = "active";
export const POPFMCADDON = "popfmcaddon";
export const ALLOW_FMC_MERGE = "AllowFMCMerge";
export const ALLOW_B2B_FMC_MERGE = "Allow_B2B_FMC Merge";
export const CSRFTOKEN = "csrftoken";
export const GREENFIELD_TAG = "GREENFIELD";
export const NAVIGATION_STATE_SESSION_KEY = "NavigationStateSessionKey";
export const NAVIGATION_ROUTES_SESSION_KEY = "NavigationRoutesSessionKey";
export const FIRST_DAY_OF_WEEK = 1;
export const MSISDN_WITH_OPTIONAL_COUNTRY_CODE = "^(38)?0[0-9]{9}$";
export const FMC_MERGE_PRODUCT_WAITING_ERROR_MESSAGE = "Merge is not possible, subscription has product in waiting status";
export const FMC_MERGE_ALREADY_CONVERTED_ERROR_MESSAGE = "is already converted to FMC";
export const FMC_MERGE_FTTB_SUBSCRIPTION_ERROR_MESSAGE = "FTTB Subscription";
export const B2B_BILLING_ACCOUNT_TYPE = "4003";
export const POSTPAID_PLAN = "postpaid";

export { config };
