import React, { FC } from "react";
import { useIntl } from "react-intl";
import { config } from "../../config/constants";
import { FMCMessages } from "../../modules/fmc/FMC.messages";
import { useStoreContext } from "../components/navigation/StepStateProvider";
import { ErrorMessages } from "./Error.messages";

const { FAST_CONNECTION_PACKAGE_CODE, SERVICE_MASTER_PACKAGE_CODE, FTTB_PAUSE } = config;
const errorCodes = {
	[FAST_CONNECTION_PACKAGE_CODE]: ErrorMessages.fastConnectionActive,
	[SERVICE_MASTER_PACKAGE_CODE]: ErrorMessages.serviceMasterActive,
	[FTTB_PAUSE]: ErrorMessages.fttbPausePackage,
	fmcNotAllowedForPostpaid: ErrorMessages.fmcNotAllowedForPostpaid,
	fmcNotPossible: ErrorMessages.fmcNotPossible,
	subscriptionNotFoundForMsisdn: ErrorMessages.subscriptionNotFoundForMsisdn,
	fmcPostpaidMergeForGsmSuspendedSubscriptionProhibited: ErrorMessages.fmcPostpaidMergeForGsmSuspendedSubscriptionProhibited,
	fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge: ErrorMessages.fmcPostpaidOnlyActiveFttbSubscriptionAllowedToMerge,
	fmcPostpaidOnlyActiveAndSuspenedFttbSubscriptionAllowedToMerge: ErrorMessages.fmcPostpaidOnlyActiveAndSuspenedFttbSubscriptionAllowedToMerge,
	billingAccountNotActive: ErrorMessages.billingAccountNotActive,
	baFraudBarring: ErrorMessages.baFraudBarring,
	subFraudBarring: ErrorMessages.subFraudBarring,
	msisdnPortingRequestOngoing: ErrorMessages.msisdnPortingRequestOngoing,
	isTemporaryMsisdn: ErrorMessages.isTemporaryMsisdn,
	noContactPhoneErorr: ErrorMessages.noContactPhoneErorr,
	tvTunerDebt: ErrorMessages.tvTunerDebt,
	fmcNotAllowedForPrepaid: ErrorMessages.fmcNotAllowedForPrepaid,
	anonymousNotAllowed: ErrorMessages.anonymousNotAllowed,
	notAllowFmcMerge: ErrorMessages.notAllowFmcMerge,
	notAllowFmcMergePreviousDone: ErrorMessages.notAllowFmcMergePreviousDone,
	notAllowFmcMergeOngoingMerge: ErrorMessages.notAllowFmcMergeOngoingMerge,
	previousFmcMergeFailed: ErrorMessages.previousFmcMergeFailed,
	searchNewFttbSubscriptionFailed: FMCMessages.searchNewFttbSubscriptionFailed,
	unpairFmcFailed: ErrorMessages.unpairFmcFailed,
	fttbPauseActive: ErrorMessages.fttbPauseActive,
	currentPaymentStatus: ErrorMessages.currentPaymentStatus,
	customerNotIdentified: ErrorMessages.customerNotIdentified,
	personalServicePkgNotActive: ErrorMessages.personalServicePkgNotActive,
	isRouterWaitingForActivate: ErrorMessages.isRouterWaitingForActivate,

};
const getErrors = (errors, intl): string|undefined => errors?.map(code => errorCodes[code] ? intl.formatMessage(errorCodes[code]) : code).join("; ");

const Template = ({children}) => (<div className="alert alert-danger d-flex justify-content-between align-items-center w-100 mt-2">
	<div>
		<i className="fas fa-exclamation-circle"></i>
		{children}
	</div>
</div>);

const Errors: FC = () =>  {
	const {state: {errors, msisdnErrors}} = useStoreContext();
	const intl = useIntl();
	const translatedErrors = getErrors(errors, intl);
	const keys = msisdnErrors && Object.keys(msisdnErrors);
	let hasMsisdnErrors = false;
	const translatedMsisdnErrors = msisdnErrors && keys?.reduce((acc, key) => {
		const errors = getErrors(msisdnErrors[key], intl);
		if (errors) hasMsisdnErrors = true;
		return {
			...acc,
			[key]: errors
		};
	}, {});
	const hasStandartErrors = translatedErrors && translatedErrors.length > 0;

	
	return (<>
		{hasStandartErrors && <Template>{translatedErrors}</Template>}
		{hasMsisdnErrors && translatedMsisdnErrors && <Template>{Object.keys(translatedMsisdnErrors)?.map(msisdn => {
			return <React.Fragment key={msisdn}>
				&nbsp;{msisdn} :&nbsp; {translatedMsisdnErrors[msisdn]}
			</React.Fragment>;
		})}</Template>}
	</>
	);

};

export { Errors };