import gql from "graphql-tag";
import { bundle } from "./Packages.fragments";
import { language } from "./Languages.fragment";

const SubscriptionClient =  {
	fragments: {
		activePackages: gql`
            fragment ActivePackages on Subscription {
              active_packages {
                ...bundleFull,
                service_parameters
                  tags
              }
            }
            ${bundle.fragments.full}
            `,
		availablePackages: gql`
            fragment AvailablePackages on Subscription {
              available_packages {
                ...bundleFull
              }
            }
            ${bundle.fragments.full}
            `,
		owner: gql`
            fragment Owner on Subscription {
              owner {
                id,
                first_name,
                last_name,
                billing_accounts {
                    id,
                    name,
                    invoice_delivery_method,
                    payment_method,
                }
              }
            }
            `,
		ownerContactPhone: gql`
            fragment OwnerContactPhone on Subscription {
              owner {
                id,
                contact_phone,
                address{
                    gis_city_id
                }
              }
            }
            `,
		user: gql`
            fragment User on Subscription {
              user {
                id,
              }
            }
            `,
		availableFamilies:  gql`
            fragment AvailableFamilies on Subscription {
              available_families {
                  id,
                  name {
                      ...languageFull
                  },
                  packages {
                      ...bundleFull
                  }
              }
            },
            ${bundle.fragments.full},
            ${language.fragments.full}
            `,
		simCardIcc: gql`
            fragment SimCardIcc on Subscription {
                sim_card {
                    icc
                }
            }
        `
	}
};

export {
	SubscriptionClient
};
